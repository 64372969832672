module.exports = {
  DEV: {
    newrelicApplicationId: '1120313461',
    newrelicLicenseKey: 'NRJS-c79c0b8f95200184ea4',
    newrelicAccountId: '4029335',
    newrelicTrustKey: '4139912',
  },
  UAT: {
    newrelicApplicationId: '1120313462',
    newrelicLicenseKey: 'NRJS-c79c0b8f95200184ea4',
    newrelicAccountId: '4029335',
    newrelicTrustKey: '4139912',
  },
  PRD: {
    newrelicApplicationId: '1120313463',
    newrelicLicenseKey: 'NRJS-c79c0b8f95200184ea4',
    newrelicAccountId: '4029335',
    newrelicTrustKey: '4139912',
  },
};