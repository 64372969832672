import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import Header from 'components/header';
import Footer from 'components/footer';
import GlobalStyle from 'global.css.js';
import { Location } from '@reach/router';
import { withResizeDetector } from 'react-resize-detector';
import useNewRelic from '../../helpers/newrelic';

const Layout = ({ data, children, meta, width }) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  useNewRelic();

  useEffect(() => {
    let headerHeight;
    let footerHeight;
    // Calculate header height
    if (width <= 966 && devicePixelRatio !== 2) {
      headerHeight = 70;
    } else {
      headerHeight = 104;
    }

    // Calculate footer height
    if (width <= 766) {
      footerHeight = 588;
    } else if (width > 766 && width <= 906) {
      footerHeight = 292;
    } else if (width > 906 && width <= 992) {
      footerHeight = 326;
    } else {
      footerHeight = 170;
    }

    setHeaderHeight(headerHeight);
    setFooterHeight(footerHeight);
  }, [width]);

  return (
    <div>
      <GlobalStyle />
      <Head {...meta} />
      <Location>
        {({ location }) => (
          <div>
            <Header
              selectedLanguage={
                location.pathname.split('/')[1] ||
                data.site.siteMetadata.languages.defaultLangKey
              }
              mainMenu={data.site.siteMetadata.mainMenu}
              logo={data.headerJson.logo}
              title={data.headerJson.title}
            />
            <div
              className={'website-container'}
              style={{
                minHeight: `calc(107vh - ${headerHeight + footerHeight}px)`,
              }}
            >
              {children}
            </div>
            {location.pathname.split('/')[1] === '500' ? (
              ''
            ) : (
              <Footer
                upper={location.pathname.indexOf('advanced-study-search') > 1}
                selectedLanguage={
                  location.pathname.split('/')[1] === '404'
                    ? data.site.siteMetadata.languages.defaultLangKey
                    : location.pathname.split('/')[1] ||
                      data.site.siteMetadata.languages.defaultLangKey
                }
                footerMenu={data.site.siteMetadata.footerMenu}
                logo={data.headerJson.logo}
                title={data.headerJson.title}
                poweredText={data.footerJson.poweredText}
                poweredUrl={data.footerJson.poweredUrl}
                facebook={data.footerJson.facebook}
                twitter={data.footerJson.twitter}
                youtube={data.footerJson.youtube}
                flickr={data.footerJson.flickr}
                linkedin={data.footerJson.linkedin}
                instagram={data.footerJson.instagram}
              />
            )}
          </div>
        )}
      </Location>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  width: PropTypes.any,
  meta: PropTypes.object,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
            mainMenu
            footerMenu
            languages {
              defaultLangKey
              langs
              defaultPageUrl
            }
          }
        }
        headerJson {
          title
          logo {
            childImageSharp {
              fluid(maxWidth: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        footerJson {
          poweredUrl
          poweredText
          facebook
          twitter
          youtube
          flickr
          linkedin
          instagram
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object,
};

export default withResizeDetector(LayoutWithQuery);
