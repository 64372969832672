import React from 'react';
import PropTypes from 'prop-types';
import {ContainerHeader} from './header.css';
import Nav from 'components/nav';
import {StaticQuery, graphql } from 'gatsby';
import {NavLink, Row, Col} from 'reactstrap';
import Img from 'gatsby-image';

const Header = ({data,  mainMenu, selectedLanguage, logo, title}) => {

  return (
    <div >
      <ContainerHeader>
        <Row className={'header-menu '}>
          <Col xs={12} sm={12} md={12} lg={12} className={'icon-holder'}>
              <NavLink className={'logo-img'} href={`/${selectedLanguage}/`}>
                <Img
                  fluid={logo.childImageSharp.fluid}
                  alt={title}
                />
              </NavLink>
          </Col>
          <Col className={'menu-list desktop'} xs={{size:12, offset:0}} sm={{size:12, offset:0}} md={{size:12, offset:0}} lg={{size:10, offset:2}} xl={{size:10, offset:2}}>
            {mainMenu && mainMenu.length && (
              <div className={'header-container'}>
                <Nav
                  selectedLanguage={selectedLanguage}
                  menuItems={data.pageMetadataJson.pages.filter((page) => {
                    if((page.url.includes('http') || page.url === 'contactUs' ) && page.menuTitle !== 'Learn About Clinical Studies') {
                      return page.pageLang === selectedLanguage && mainMenu.includes(page.url)
                    } else
                      return undefined
                  }).sort((a, b) => {
                    return mainMenu.indexOf(a.url) - mainMenu.indexOf(b.url);
                  })}
                  className={'top-navigation'}
                />
                <Nav
                  selectedLanguage={selectedLanguage}
                  menuItems={data.pageMetadataJson.pages.filter((page) => {
                    if((!page.url.includes('http')  || page.menuTitle === 'Learn About Clinical Studies') && page.url !== 'contactUs'){
                      return page.pageLang === selectedLanguage && mainMenu.includes(page.url)
                    } else
                      return undefined
                  }).sort((a, b) => {
                    return mainMenu.indexOf(a.url) - mainMenu.indexOf(b.url);
                  })}
                  className={'main-navigation'}
                />
              </div>
            )}
          </Col>
          <Col className={'menu-list mobile'} xs={{size:12, offset:0}} sm={{size:12, offset:0}} md={{size:12, offset:0}} lg={{size:10, offset:2}}>
            {mainMenu && mainMenu.length && (
              <div className={'header-container'}>
                <Nav
                  selectedLanguage={selectedLanguage}
                  menuItems={data.pageMetadataJson.pages.filter((page) => {
                    return page.pageLang === selectedLanguage && mainMenu.includes(page.url)
                  }).sort((a, b) => {
                    return mainMenu.indexOf(a.url) - mainMenu.indexOf(b.url);
                  })}
                />
              </div>
            )}
          </Col>
        </Row>
      </ContainerHeader>
    </div>
  );
};

const HeaderWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            mainMenu
          }
        }
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);

export default HeaderWithQuery;

Header.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  mainMenu: PropTypes.array.isRequired,
  selectedLanguage: PropTypes.any.isRequired,
  logo: PropTypes.any.isRequired,
};
