import styled, { createGlobalStyle } from 'styled-components';
import {
  buttonBG,
  fontColor,
  fontColorLight,
  footerBGGrey,
  white,
  activeColor,
  headerBorder,
  yellow,
  hoverColor,
  blueHover,
  generalBgGrey,
  inputDark,
} from 'constants/theme';
import Regular from './fonts/regular.otf2';
import Medium from './fonts/medium.otf2';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
  }
   @font-face {
    font-family: "Akzidenz-Grotesk Pro";
    src: url('${Regular}') format("opentype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Akzidenz-Grotesk Pro";
    src: url('${Medium}') format("opentype");
    font-weight: bold;
  }

  html, body {
    font-family: "Akzidenz-Grotesk Pro";
    font-weight: normal;
    overflow-x: hidden;
    position: relative;
  }
  .website-container {
    min-height: 66vh;
    padding-top: 104px;
    justify-content: center;
    display: flex;
  }
  // Tablet si Mobile
  @media (min-width: 320px) and (max-width: 768px) {
    .website-container {
      min-height: 66vh;
      padding-top: 70px;
      justify-content: center;
      display: flex;
    }
  }

  // Tablet si Mobile
  @media (min-width: 766px) and (max-width: 1024px) {
    .website-container {
      padding-top: 70px;
    }


  }
  //IPAD Pro
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .website-container {
      padding-top: 104px;
    }
  }

  .grecaptcha-badge {
    visibility: hidden; 
  }
  
  [data-reach-dialog-overlay] {
    overflow: scroll;
    z-index: 999
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .details-page-mobile {
      padding: 0 20px;
    }
  }
   @media (min-width: 768px) and (max-width: 1024px) {
    .details-page-mobile {
      padding: 0 20px;
    }
  }
`;
export const ContainerHolder = styled.div`
  max-width: 1110px;
  width: 100%;
  @media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: 1024px;
    width: auto;
  }
  @media (max-width: 767px) and (min-width: 320px) {
    .search-home {
      .suggestions,
      .autocomplete-dropdown-container {
        margin-right: 15px;
      }
      .location-section {
        margin-top: 20px;
        padding-right: 15px;
      }
      .keyword-section {
        padding-right: 15px;
      }
    }
  }
`;

export const Fonts = styled.div`
  width: 100%;
  max-width: 1110px;
  &.homePage {
    max-width: none;
  }
  @media (max-width: 1160px) {
    padding: 0 20px;
    &.homePage {
      padding: 0;
    }
  }
  h1 {
    color: ${white};
    font-size: 50px;
    line-height: 64px;
    font-weight: bold;
  }
  h2 {
    color: ${fontColor};
    font-size: 37px;
    line-height: 48px;
    font-weight: bold;
  }
  h3 {
    color: ${fontColor};
    font-size: 28px;
    line-height: 36px;
    font-weight: bold;
  }
  h4 {
    color: ${fontColor};
    font-size: 21px;
    line-height: 27px;
    font-weight: bold;
  }
  p {
    color: ${fontColor};
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }
  @media (min-width: 320px) and (max-width: 1024px) {
    h1 {
      color: ${white};
      font-size: 33px;
      line-height: 42px;
      font-weight: bold;
    }
    h2 {
      color: ${fontColor};
      font-size: 27px;
      line-height: 35px;
      font-weight: bold;
    }
    h3 {
      color: ${fontColor};
      font-size: 23px;
      line-height: 30px;
      font-weight: bold;
    }
    h4 {
      color: ${fontColor};
      font-size: 19px;
      line-height: 25px;
      font-weight: normal;
    }
    p {
      color: ${fontColor};
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
    }
  }
  @media (min-width: 767px) and (max-width: 1024px) {
  }
`;

export const PublicationHero = styled.div`
  margin: 40px 0;
  position: relative;
  h1 {
    color: ${fontColor};
    font-size: 28px;
    line-height: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p {
    color: ${fontColor};
    font-size: 16px;
    line-height: 24px;
  }
  .hero-content {
    width: 540px;
    padding: 30px 30px 30px 65px;
    opacity: 0.9;
    border-radius: 0 4px 4px 0;
    background-color: ${footerBGGrey};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ::before {
      content: '';
      position: absolute;
      width: 5px;
      background-color: ${activeColor};
      border-radius: 2.5px;
      top: 30px;
      left: 30px;
      bottom: 30px;
    }
  }
  @media (min-width: 320px) and (max-width: 992px) {
    .hero-content {
      width: 100%;
      position: relative;
      margin-top: 30px;
      background-color: transparent;
      top: 0;
      transform: none;
      padding: 0 0 0 35px;
      ::before {
        left: 0;
        top: 0;
        bottom: 0;
      }
    }
    .gatsby-image-wrapper {
      border-radius: 16px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
        0 30px 40px 0 rgba(0, 0, 0, 0.2);
    }
  }
  @media (max-width: 575px) {
    h1 {
      font-size: 23px;
      line-height: 30px;
    }
  }
`;

export const PublicationSearch = styled.div`
  border: 1px solid ${footerBGGrey};
  border-radius: 16px;
  background-color: ${white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 30px 40px 0 rgba(0, 0, 0, 0.2);
  padding: 40px;
  margin-bottom: 40px;
  p {
    color: ${activeColor};
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 18px;
    font-weight: bold;
  }
  label {
    color: ${fontColor};
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 8px;
  }
  input {
    width: 100%;
    height: 40px;
    border: 1px solid ${headerBorder};
    border-radius: 4px;
    font-size: 14px;
    padding: 8px 16px;
    line-height: 24px;
    color: #212529;
    ::placeholder {
      color: #21252980;
    }
    &:focus {
      outline: none;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
        0 10px 20px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .select-dropdown {
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 40px;
      border: 1px solid ${headerBorder};
      border-radius: 4px;
      background-color: ${white};
      color: #212529;
      font-size: 14px;
      line-height: 24px;
      text-align: left;
      padding: 8px 16px;
      img {
        height: 10px;
        width: 17px;
      }
      &:focus,
      &:active {
        outline: none;
        border-radius: 3px 3px 0 0;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
          0 10px 20px 0 rgba(0, 0, 0, 0.1);
      }
    }
    .dropdown-menu {
      width: 100%;
      padding: 10px 16px;
      border-radius: 0 0 3px 3px;
      border-top: 0;
      margin: 0;
      background-color: #f9f9f9;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
        0 10px 20px 0 rgba(0, 0, 0, 0.1);
      .dropdown-item {
        color: #544f40;
        background-color: transparent;
        height: auto;
        border: none;
        padding: 0;
        &:hover {
          color: ${activeColor};
        }
        &:active {
          box-shadow: none;
        }
      }
    }
  }
  @media (max-width: 575px) {
    input {
      margin-bottom: 30px;
    }
  }
`;

export const ClinicalPublicationTabel = styled.div`
  table {
    border: 0;
    background-color: ${activeColor};
    border-radius: 4px 4px 4px 4px;
    margin-bottom: 20px;
    border-collapse: separate;
    thead {
      tr {
        border-radius: 4px 4px 0 0;
        // height: 60px;
        th {
          border: 0;
          border-left: 1px solid ${activeColor};
          cursor: pointer;
          color: ${white};
          font-size: 16px;
          line-height: 21px;
          font-weight: bold;
          border-radius: 0 4px 0 0;
          padding: 20px 15px;
          vertical-align: middle;
          &.year-section {
            border-right: 1px solid ${activeColor};
            width: 175px;
            border-radius: 4px 0 0 0;
          }
          img {
            height: 9px;
            float: right;
            margin-top: 6px;
            &.inactive {
              opacity: 0.6;
            }
          }
        }
      }
    }
    tbody {
      tr {
        &:last-child {
          td {
            border-radius: 0 0 4px 0;
            &.year-section {
              border-radius: 0 0 0 4px;
            }
          }
        }
        height: auto;
        td.no-filter {
          text-align: center;
          color: #15717d;
          font-size: 16px;
          font-weight: bold;
          background-color: ${white};
          border-left: 1px solid ${headerBorder};
        }
        td {
          &.grey {
            background-color: ${footerBGGrey};
          }
          &.white {
            background-color: ${white};
          }
          border: 0;
          border-right: 1px solid ${headerBorder};
          border-bottom: 1px solid ${headerBorder};
          padding: 20px 15px;
          color: ${fontColor};
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          vertical-align: middle;
          &.year-section {
            border-left: 1px solid ${headerBorder};
            text-align: center;
            line-height: 24px;
            font-weight: bold;
            font-size: 16px;
            color: #15717d;
          }
        }
      }
    }
  }
  .pagination-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    p {
      color: ${fontColor};
      font-size: 16px;
      line-height: 21px;
    }
    .pagination {
      height: 35px;
      li {
        height: 35px;
        width: 35px;
        border-top: 1px solid ${headerBorder};
        border-bottom: 1px solid ${headerBorder};
        border-right: 1px solid ${headerBorder};
        &:focus {
          outline: none;
        }
        &.previous {
          border-radius: 4px 0 0 4px;
          border-left: 1px solid ${headerBorder};
          a {
            background-image: url(/icons/pagination-left.svg);
            background-position-x: center;
            background-repeat: no-repeat;
            background-size: 14px;
            background-position-y: center;
          }
          &:hover {
            a {
              background-image: url(/icons/pagination-left-hover.svg);
            }
          }
        }
        &.next {
          border-radius: 0 4px 4px 0;
          a {
            background-image: url(/icons/pagination-right.svg);
            background-position-x: center;
            background-repeat: no-repeat;
            background-size: 14px;
            background-position-y: center;
          }
          &:hover {
            a {
              background-image: url(/icons/pagination-right-hover.svg);
            }
          }
        }
        a {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          color: ${fontColor};
          font-size: 12px;
          line-height: 15px;
          &:focus {
            outline: none;
          }
        }
        &.disabled {
          &.next {
            background-color: ${white} !important;
            border: 1px solid ${headerBorder} !important;
            a {
              background-image: url(/icons/pagination-right.svg) !important;
              cursor: default;
            }
          }
          &.previous {
            background-color: ${white} !important;
            border: 1px solid ${headerBorder} !important;
            a {
              background-image: url(/icons/pagination-left.svg) !important;
              cursor: default;
            }
          }
        }
        &.active,
        &:hover {
          border: 1px solid ${activeColor};
          background-color: ${activeColor};
          a {
            color: ${white};
            font-size: 12px;
            line-height: 15px;
            text-decoration: none;
          }
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 1024px) {
    .pagination-section {
      .pagination {
        li {
          &.previous {
            &:hover {
              border: 1px solid ${headerBorder} !important;
              background-color: ${white} !important;

              a {
                background-image: url(/icons/pagination-left.svg) !important;
              }
            }
          }
          &.next {
            &:hover {
              border: 1px solid ${headerBorder} !important;
              background-color: ${white} !important;
              a {
                background-image: url(/icons/pagination-right.svg) !important;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    thead {
      tr {
        th {
          vertical-align: top !important;
          &.year-section {
            width: 139px !important;
            img {
              margin-top: -15px;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 12px !important;
          line-height: 16px !important;
          word-break: break-word;
        }
      }
    }
    .pagination-section {
      flex-direction: column;
      p {
        margin-bottom: 10px;
      }
    }
  }
`;
export const SimpleSearchSection = styled.div`
  width: 100%;
`;

export const LearnMoreSection = styled.div`
  justify-content: center;
  display: flex;
  padding: 40px 0 0 0;
  margin-top: 140px;
  .main-row {
    margin: 0;
    width: 100%;
    max-width: 1110px;
  }
  .sub-row {
    margin: 0;
  }
  .main-col {
    border-bottom: 1px solid ${headerBorder};
    margin-bottom: 0px;
    padding-bottom: 40px;
    padding-left: 0;
    padding-right: 0;
    &.border-padding {
      margin-bottom: 40px;
    }
  }
  .img-holder {
    background-color: ${activeColor};
    width: 65px;
    height: 65px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: none;
    max-width: 65px;
    img {
      background-color: ${activeColor};
    }
  }
  .text-holder {
    padding: 0 0 0 20px;
    p {
      padding: 20px 0 30px 0;
      max-width: 75%;
      &.shortText {
        min-height: 122px;
      }
      &.longText {
        min-height: 98px;
      }
    }
    h3 {
      width: 75%;
    }
    .buttonStyle {
      background-color: ${buttonBG};
      border: 1px solid ${buttonBG};
      text-align: center;
      padding: 8px 16px;
      border-radius: 4px;
      color: ${white};
      font-weight: bold;
      font-size: 16px;
      line-height: 33px;
      &:focus {
        outline: none;
      }
      &:hover {
        background-color: ${blueHover};
        text-decoration: none;
      }
    }
  }
  // Tablet
  @media (min-width: 766px) and (max-width: 1024px) {
    padding: 0 20px 0 20px;
    margin-top: 160px;
    .text-holder {
      padding: 40px 0 0 20px;
      h3 {
        max-width: 70%;
      }
      p {
        min-height: 120px;
        max-width: 68%;
        padding: 20px 0 0px 0;
        &.shortText {
          min-height: 120px;
        }
        &.longText {
          min-height: 120px;
        }
      }
    }
    .main-col {
      margin-bottom: 0px;
      &.border-padding {
        margin-bottom: 0px;
      }
    }

    .img-holder {
      margin-top: 40px;
      width: 45px;
      height: 45px;
    }
  }
  // Tablet Landscape
  @media (min-width: 766px) and (max-width: 1024px) and (orientation: landscape) {
    margin-top: 120px;
    .text-holder {
      p {
        min-height: 120px;
        max-width: 77%;
      }
    }
  }
  //IPAD Pro
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .text-holder {
      p {
        min-height: 120px;
        max-width: 77%;
      }
    }
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 766px) {
    margin-top: 470px;
    padding: 0 20px;
    .text-holder {
      h3 {
        max-width: 100%;
      }
      p {
        min-height: 120px;
        padding: 10px 0 20px 0;
        max-width: 85%;
        &.shortText {
          min-height: 10px;
        }
        &.longText {
          min-height: 10px;
        }
      }
    }
    .main-col {
      margin-bottom: 40px;
    }
    .img-holder {
      width: 45px;
      height: 45px;
      img {
        width: 22px;
        &.infoIcon {
          width: 9px;
        }
      }
    }
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 766px) and (orientation: landscape) {
    margin-top: 375px;
  }
`;
export const TextSection = styled.div`
  .row {
    padding-bottom: 40px;
    &.paired {
      padding-bottom: 30px;
    }
  }
  .img-holder {
    justify-content: center;
    display: flex;
  }
  .container-3gsk {
    justify-content: center;
    display: flex;
    &.griBg {
      background-color: ${footerBGGrey};
    }
  }
  h2 {
    text-align: center;
    padding-bottom: 40px;
    padding-top: 40px;
    max-width: 69%;
    margin 0 auto;
    position: relative;
    .anchor01 {
      padding-top: 0;
    }
    span {
      position: absolute;
      top: -100px;
    }
  }
  h4 {
    padding-bottom: 20px;
    font-weight: bold;
  }
  p{
    padding-bottom: 10px;
  }
  a {
    color: ${buttonBG};
    text-decoration: none;
    &:hover {
      color: ${blueHover};
      text-decoration: none;
    }
  }
  .gatsby-image-wrapper {
    width: 444px;
    height: 250px;
    border-radius: 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
      0 30px 40px 0 rgba(0, 0, 0, 0.2);
  }

   // Tablet
  @media (min-width: 766px) and (max-width: 1024px) {
    .gatsby-image-wrapper {
      width: 100%;
    }
    .img-holder {
      padding-bottom: 40px;
    }
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 766px) {
  padding: 0 20px;
  .row {
    padding-bottom: 20px;
    &.paired {
      padding-bottom: 20px;
    }
  }
  .gatsby-image-wrapper {
    // width: 334px;
    height: 189px;
  }
    .img-holder {
      padding-bottom: 40px;
    }
    h2 {
      max-width: 100%;
      &.first-h2 {
        padding-top: 0px;
      }
    }
    h4 {
      padding-bottom: 20px;
      max-width: 85%;
    }
    .text-holder {
      order: 2;
      p {
        padding-bottom: 20px;
      }
      button {
        padding: 0 0 20px 0;
      }
    }
    .griBg {
      margin: 0 -20px;
      .main-div {
        margin: 0 20px;
      }
    }
  }
`;
export const HomeHero = styled.div`
  background: linear-gradient(45deg, ${activeColor} 0%, ${yellow} 100%);
  width: 100%;
  height: 300px;
  left: 0;
  display: flex;
  justify-content: center;
  .hero-content {
    // max-width: 1110px;
    // width: 100%;
    padding: 60px 0px 50px 0px;
    margin: 0 auto;
    position: relative;
    ::before {
      content: '';
      position: absolute;
      width: 5px;
      background-color: ${yellow};
      border-radius: 2.5px;
      top: 60px;
      left: 0;
      bottom: 40px;
      max-height: 111px;
    }
    .searchBox {
      max-width: 1110px;
      text-align: center;
      background-color: ${white};
      border: 1px solid ${footerBGGrey};
      height: auto;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
        0 30px 40px 0 rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      margin-top: 50px;
      padding: 0;
    }
    .text-box {
      padding-left: 22px;
    }
  }

  p {
    margin-top: 12px;
    color: ${white};
    font-size: 19px;
    line-height: 25px;
  }
  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) {
    .hero-content {
      margin: 0 20px;
      width: auto;
      .text-box {
        padding-left: 20px;
      }
      .searchBox {
        max-width: 1110px;
        text-align: center;
        background-color: ${white};
        border: 1px solid ${footerBGGrey};
        height: auto;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
          0 30px 40px 0 rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        margin-top: 50px;
      }
    }
  }

  //TABLET Landscape
  @media (min-width: 767px) and (max-width: 1024px) and (orientation: landscape) {
    .hero-content {
      .searchBox {
        height: auto;
      }
    }
  }
  //IPAD Pro
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .hero-content {
      .searchBox {
        height: auto;
      }
    }
  }
  //MOBILE
  @media (min-width: 320px) and (max-width: 766px) {
    .hero-content {
      width: auto;
      padding: 40px 0px 50px 0px;
      margin: 0 20px;
      ::before {
        max-height: 145px;
        top: 40px;
      }
      .searchBox {
        padding: 0;
        height: auto;
        margin-top: 40px;
      }
    }
  }
  //MOBILE Landscape
  @media (min-width: 320px) and (max-width: 766px) and (orientation: landscape) {
    .hero-content {
      ::before {
        max-height: 111px;
      }
      .searchBox {
        padding: 0;
        height: auto;
      }
    }
  }
`;
export const SocialMediaResults = styled.div`
  width: 100%;
  text-align: right;
  .mobileShare-btn {
    display: none;
  }
  .media-row {
    justify-content: flex-end;
  }
  p {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: ${white};
    display: block;
  }
  a {
    svg {
      font-size: 18px
      color: ${white};
    }
    padding-left: 9px;
  }
  button {
    border: none;
    background-color: transparent;
    padding: 0;
    padding-left: 8px;
    svg {
      font-size: 18px
      color: ${white};

    }
  }
   //MOBILE
  @media (min-width: 320px) and (max-width: 766px) {
    text-align: left;
    padding-left: 20px;
    display: inline-block;
    &.closedShare {
      display: none;
    }
    &.openShare {
      display: block;
    }
    .media-row {
      background-color: ${activeColor};
      border-radius: 18px;
      border: 1px solid ${activeColor};
      max-width: 188px;
      height: 36px;
      a {
        line-height: 36px;
        padding: 9px;
      }
      button {
        line-height: 36px;
        padding: 0 9px;
      }
      &.details-media-row {
        margin-top: 0px;
      }
    }
    p {
      display: none;
    }
  }
`;
export const SearchHero = styled.div`
  background: linear-gradient(45deg, #15717d 0%, #00b4cd 100%);
  width: 100%;
  height: 300px;
  left: 0;
  display: flex;
  justify-content: center;
  .hero-content {
    // max-width: 1140px;
    // width: 100%;
    padding: 60px 0px 50px 0px;
    position: relative;
    margin: 0 auto;
    ::before {
      content: '';
      position: absolute;
      width: 5px;
      background-color: ${yellow};
      border-radius: 2.5px;
      top: 60px;
      left: 0;
      bottom: 40px;
      max-height: 111px;
    }
    .hero-text {
      padding-left: 0px;
    }
    .social-media {
      margin: 0;
      padding: 0;
      .mobile-share {
        display: none;
      }
    }
    .searchBox {
      max-width: 1110px;
      text-align: center;
      background-color: ${white};
      border: 1px solid ${footerBGGrey};
      height: auto;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
        0 30px 40px 0 rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      margin-top: 50px;
      padding: 0;
      z-index: 23;
    }
  }
  h1 {
    padding-left: 25px;
    color: ${white};
    font-size: 50px;
    font-weight: bold;
    line-height: 64px;
  }
  h4 {
    margin-top: 12px;
    color: ${white};
    font-size: 19px;
    line-height: 25px;
    &.hero-counter {
      padding-left: 25px;
    }
  }
  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) {
    .hero-content {
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 0;
      width: auto;
      ::before {
        left: 20px;
      }
      .hero-text {
        padding-left: 0px;
      }
      h1 {
        font-size: 33px;
      }
      .text-box {
        padding-left: 20px;
      }
      .social-media {
        padding-right: 20px;
      }
      .searchBox {
        max-width: 1110px;
        text-align: center;
        background-color: ${white};
        border: 1px solid ${footerBGGrey};
        height: auto;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
          0 30px 40px 0 rgba(0, 0, 0, 0.2);
        border-radius: 16px;
        margin-top: 50px;
        h4 {
          color: ${fontColor};
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }

  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) and (orientation: landscape) {
    .hero-content {
      .searchBox {
        height: auto;
      }
    }
  }
  //IPAD Pro
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
    .hero-content {
      .searchBox {
        height: auto;
      }
    }
  }

  //MOBILE
  @media (min-width: 320px) and (max-width: 766px) {
    .hero-content {
      width: auto;
      margin-left: 0;
      padding-top: 40px;
      ::before {
        max-height: 80px;
        margin-left: 20px;
        top: 40px;
      }
      .hero-text {
        padding-left: 20px;
      }
      .social-media {
        margin: 30px 0 40px 0;
        .mobile-share {
          padding-left: 20px;
          display: block;
          .shareSvgPlaceholder {
            display: inline-block;
            background-color: ${activeColor};
            padding: 8px;
            border-radius: 50%;
            .mobileShare-btn {
              display: block;
              color: ${white};
              font-size: 21px;
              background-color: ${activeColor};
              border: 1px solid ${activeColor};
            }
          }

          p {
            font-size: 16px;
            font-weight: bold;
            line-height: 32px;
            color: ${white};
            display: inline-block;
            padding-left: 10px;
            vertical-align: top;
          }
          &.open {
            display: block;
          }
          &.closed {
            display: none;
          }
        }
      }
      .searchBox {
        height: auto;
        margin: 0 20px;
      }
      h1 {
        padding-left: 25px;
        font-size: 33px;
        line-height: 42px;
      }
    }
  }
  //MOBILE
  @media (min-width: 320px) and (max-width: 766px) and (orientation: landscape) {
    .hero-content {
      ::before {
        max-height: 80px;
      }
    }
  }
`;

export const ShadowSec = styled.div`
  height: 585px;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: ${footerBGGrey};
  top: 1708px;
`;

export const SiteMapHero = styled.div`
  background: linear-gradient(45deg, ${activeColor} 0%, ${yellow} 100%);
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  div {
    max-width: 1110px;
    width: 100%;
    padding: 60px 50px 50px 25px;
    position: relative;
    ::before {
      content: '';
      position: absolute;
      width: 5px;
      background-color: ${yellow};
      border-radius: 2.5px;
      top: 60px;
      left: 0;
      bottom: 40px;
    }
  }
  h1 {
    color: ${white};
    font-size: 50px;
    font-weight: bold;
    line-height: 64px;
  }
  p {
    margin-top: 12px;
    color: ${white};
    font-size: 19px;
    line-height: 25px;
  }
  @media (max-width: 1160px) and (min-width: 768px) {
    div {
      padding: 60px 50px 50px 50px;
      ::before {
        left: 20px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    div {
      padding: 40px 40px 50px 46px;
      ::before {
        left: 20px;
        top: 40px;
      }
    }
    h1 {
      font-size: 33px;
      line-height: 42px;
    }
  }
`;

export const SiteMapContent = styled.div`
  margin-top: 247px;
  margin-bottom: 40px;
  font-size: 16px;
  p {
    margin-bottom: 10px;
    ::before {
      content: '•';
      color: ${fontColor};
      font-size: 16px;
      display: inline-block;
    }
  }
  a {
    color: ${activeColor};
    font-size: 16px;
    line-height: 24px;
    padding-left: 8px;
    &:hover {
      color: ${hoverColor};
      text-decoration: none;
    }
  }
  li {
    display: flex;
    ::before {
      content: '•';
      color: ${fontColor};
      font-size: 16px;
      display: inline-block;
      margin-top: 2px;
    }
  }
  ul {
    margin-left: 20px;
    margin-bottom: 10px;
    list-style: none;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    margin-top: 234px;
  }
  @media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
    margin-top: 209px;
  }
`;

export const HeroAdv = styled.div`
  background: linear-gradient(45deg, #312783 0%, #e8308a 100%);
  position: absolute;
  width: 100%;
  height: 300px;
  left: 0;
  display: flex;
  justify-content: center;
  div {
    max-width: 1110px;
    width: 100%;
    padding: 60px 50px 129px 25px;
    position: relative;
    ::before {
      content: '';
      position: absolute;
      width: 5px;
      background-color: ${yellow};
      border-radius: 2.5px;
      top: 60px;
      left: 0;
      bottom: 129px;
    }
    p {
      color: ${white};
      font-size: 19px;
      line-height: 25px;
      margin-top: 12px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 1160px) and (min-width: 768px) {
    div {
      padding-left: 45px;
      ::before {
        left: 20px;
      }
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    div {
      ::before {
        bottom: 170px !important;
      }
    }
  }
  @media (min-width: 340px) and (max-width: 767px) {
    div {
      padding-left: 45px;
      padding-top: 40px;
      ::before {
        left: 20px;
        top: 40px;
        bottom: 110px;
      }
    }
    h1 {
      font-size: 33px;
      line-height: 42px;
    }
  }
  @media (min-width: 320px) and (max-width: 339px) {
    div {
      padding-left: 45px;
      padding-top: 40px;
      ::before {
        top: 40px;
        left: 20px;
        bottom: 90px;
      }
    }
    h1 {
      font-size: 33px;
      line-height: 42px;
    }
  }
`;

export const ContentAdv = styled.div`
  margin-top: 300px;
  h4 {
    color: #544f40;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  .genderClass {
    display: inline-flex !important;
  }
  .box-sec {
    img {
      position: absolute;
      margin-left: 5px;
      margin-top: -5px;
    }
    display: flex;
    flex-direction: column;
  }
  .action-section {
    display: flex;
    justify-content: space-around;
  }
  .search-bottom {
    margin-top: 5px;
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-end;
  }
  .clearBTN {
    margin-top: 25px;
    color: #15717d;
    height: 40px;
    width: 72px;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    border: 0;
    background-color: ${white};
    &:focus {
      outline: none;
    }
    &:hover {
      color: #105962;
    }
  }
  .searchBTN {
    margin-top: 25px;
    height: 40px;
    width: 90px;
    background-color: #f36533;
    border: 1px solid #f36533;
    border-radius: 4px;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: ${hoverColor};
      border: 1px solid ${hoverColor};
    }
  }
  .search-section {
    border-radius: 16px;
    background-color: ${white};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
      0 30px 40px 0 rgba(0, 0, 0, 0.2);
    padding: 40px;
    position: relative;
    z-index: 10;
    max-width: 1140px;
    width: 100%;
    transform: translateY(-58%);
    p.warning-message {
      display: flex;
      margin: 0;
      color: #f33333;
      padding-top: 10px;
      font-size: 14px;
    }
    h4 {
      margin-top: 0;
      margin-bottom: 8px;
    }
    .suggestions,
    .autocomplete-dropdown-container {
      list-style: none;
      position: absolute;
      right: 15px;
      left: 15px;
      padding-right: -30px;
      padding: 10px 16px;
      max-height: 250px;
      overflow: hidden;
      border-top: 0;
      margin: 0;
      z-index: 10;
      max-width: 800px;
      background-color: #f9f9f9;
      border: 1px solid ${headerBorder};
      border-radius: 0 0 4px 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
        0 10px 20px 0 rgba(0, 0, 0, 0.1);
      li,
      span {
        color: #544f40;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 6px;
        text-align: left;
        cursor: pointer;
        :hover {
          color: ${activeColor};
        }
      }
      .google-powered {
        display: flex;
        justify-content: flex-end;
      }
    }
    input {
      width: 100%;
      height: 40px;
      border: 1px solid ${headerBorder};
      border-radius: 4px;
      font-size: 14px;
      padding: 8px 16px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #212529;
      &::placeholder {
        color: #21252980;
      }
      //XI EIkc*f
      @media all and (-ms-high-contrast: none) {
        &::placeholder {
          color: #21252980;
        }
      }
      &:focus {
        outline: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
          0 10px 20px 0 rgba(0, 0, 0, 0.1);
      }
      &.wrong {
        box-shadow: 0 0 4px 0 #f33333;
      }
    }
    #distance,
    #keyword {
      position: absolute;
      margin-left: 5px;
      margin-top: -5px;
    }
    .select-dropdown {
      button {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        width: 100%;
        height: 40px;
        border: 1px solid ${headerBorder};
        border-radius: 4px;
        background-color: ${white};
        color: #212529;
        font-size: 14px;
        line-height: 24px;
        text-align: left;
        padding: 8px 24px 8px 16px;
        &:disabled {
          opacity: 0.5;
          img {
            opacity: 0.5;
          }
        }
        img {
          height: 10px;
          width: 17px;
          position: absolute;
          right: 12px;
        }
        //XI EIkc*f
        @media all and (-ms-high-contrast: none) {
          img {
            top: 16px;
          }
        }
        p {
          font-size: 14px;
          line-height: 24px;
          color: #21252980;
        }
        &:focus,
        &:active {
          outline: none;
          border-radius: 3px 3px 0 0;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
            0 10px 20px 0 rgba(0, 0, 0, 0.1);
        }
      }
      .dropdown-menu {
        width: 100%;
        padding: 10px 16px;
        border-radius: 0 0 3px 3px;
        border-top: 0;
        margin: 0;
        background-color: #f9f9f9;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
          0 10px 20px 0 rgba(0, 0, 0, 0.1);
        .dropdown-item {
          color: #544f40;
          background-color: transparent;
          height: auto;
          border: none;
          padding: 0;
          :hover {
            color: ${activeColor};
          }
          &:active {
            box-shadow: none;
          }
        }
      }
    }
  }
  @media (max-width: 991px) and (min-width: 768px) {
    .check {
      width: 22px !important;
    }
    .search-section {
      padding-top: 20px;
      transform: translateY(-48%);
      h4 {
        margin-top: 20px;
      }
    }
    .action-section {
      margin-top: 20px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .search-section {
      transform: translateY(-26%);
    }
  }
  @media (min-width: 481px) and (max-width: 575px) {
    .search-section {
      transform: translateY(-24%);
    }
    .first-field {
      margin-top: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .search-section {
      transform: translateY(-24%);
    }
    .first-field {
      margin-top: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .search-section {
      input {
        font-size: 16px;
      }
      .select-dropdown {
        button,
        button p {
          font-size: 16px;
        }
      }
    }
    .action-section {
      display: none;
    }
    p.warning-message {
      position: relative;
      display: flex;
      padding-left: 0;
      font-size: 14px;
    }
    h4 {
      margin-bottom: 15px;
      margin-top: 30px;
    }
    .search-section {
      padding-top: 20px;
      h4 {
        margin-top: 20px;
      }
    }
    .mobile {
      flex-direction: row-reverse;
      display: flex !important;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 14;
      align-items: center;
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
        0 10px 20px 0 rgba(0, 0, 0, 0.1);
      width: 100%;
      button {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
  @media (min-width: 481px) and (max-width: 680px) and (orientation: landscape) {
    margin-top: 260px;
  }
`;
export const SearchResultsPage = styled.div`
  width: 100%;
  justify-content: center;
  .advanced-filter {
    margin-top: 80px;
  }
  .results-Section {
    .no-results-message {
      padding-top: 140px;
      font-size: 19px;
      line-height: 25px;
      color: ${fontColor};
      margin-bottom: 80px;
    }
  }
  .mobileShareSection {
    justify-content: flex-end;
    padding-bottom: 8px;
  }
  .mobile {
    display: none;
  }
  #filter-mobile {
    display: inline-flex;
    align-items: center;
    .icon {
      display: flex;
      height: 36px;
      width: 36px;
      background-color: #f36533;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    svg {
      color: ${white};
      height: 17px;
      width: 17px;
    }
    p {
      color: #544f40;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      margin-left: 10px;
    }
  }
  .content-holder {
    justify-content: center;
    display: flex;
    z-index: 1;
  }
  .row {
    max-width: 1440px;
    width: 100%;
  }
  @media (max-width: 991px) {
    .advanced-filter {
      display: none;
    }
    .mobile {
      display: flex;
    }
  }
  //TABLET
  @media (min-width: 991px) and (max-width: 1024px) {
    .advanced-filter {
      margin-top: 115px;
    }
    .results-Section {
      .no-results-message {
        padding-top: 80px;
        margin-bottom: 80px;
        text-align: center;
      }
    }
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 767px) {
    #filter-mobile {
      padding-top: 100px;
      margin-top: 290px;
    }
    .results-Section {
      padding: 0 20px;
      .no-results-message {
        padding-top: 80px;
        margin-bottom: 80px;
        text-align: center;
      }
    }
    .mobile {
      padding: 0 20px;
    }
    .responsiveTable {
      td {
        .tdBefore {
          left: 2rem;
        }
      }
    }td .tdBefore
  }

  //TABLET
  @media (min-width: 768px) and (max-width: 991px) {
    #filter-mobile {
      padding-top: 100px;
      margin-top: 100px;
    }
    .advanced-filter {
      margin-top: 115px;
    }
    .results-Section {
      .no-results-message {
        padding-top: 80px;
        margin-bottom: 80px;
        text-align: center;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .results-Section {
      .no-results-message {
        padding-top: 140px;
        margin-bottom: 80px;
        text-align: left;
      }
    }
   }
   //IPAD Pro
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait){
    .advanced-filter {
      margin-top: 80px;
    }
  }
   @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .results-Section {
      .no-results-message {
        padding-top: 140px;
        margin-bottom: 80px;
        text-align: left;
      }
    }
   }
`;

export const FilterMobile = styled.div`
  .content-sec {
    h5 {
      margin-bottom: 0;
    }
    margin-top: -4px;
    display: flex;
    padding: 0 20px;
    margin-bottom: 60px;
    .hero-filter {
      margin-top: 10px;
      align-items: center;
      .reset-button {
        display: none;
      }
    }
    label {
      margin-bottom: 0;
    }
    p {
      margin: 0;
    }
  }
  .footer-sec {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 14;
    width: 100%;
    display: flex;
    justify-content: space-around;
    height: 60px;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05), 0 10px 20px 0 rgba(0,0,0,0.1);
    .apply {
      background-color: #F36533;
      border: 1px solid #F36533;
      border-radius: 4px;
      height: 40px;
      max-width: 152px;
      width: 100%;
      color ${white};
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      font-weight: bold;
      margin: 0 10px 0 20px;
    }
    .clearBTN {
      color: #15717d;
      height: 40px;
      max-width: 152px;
      margin: 0 10px 0 20px;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      border: 0;
      background-color: ${white};
    }
  }
`;

export const ResultsTable = styled.div`
  border: 1px solid ${headerBorder};
  border-radius: 4px;
  .table-header {
    border-bottom: 1px solid ${activeColor};
    tr {
      th {
        color: ${fontColor};
        font-size: 14px;
        font-weight: bold;
        line-height: 52px;
        height: 52px;
        padding: 0 14px;
        white-space: nowrap;
        svg {
          color: ${fontColorLight};
          font-size: 23px;
          font-weight: normal;
          margin-left: 5px;
        }
      }
    }
  }
  .table-body {
    .clampRow {
      position: relative;
      border: none;
      &.row-unpaired {
        background-color: #f5f5f5;
      }
      .clamp-lines {
        ::before {
          position: absolute;
          z-index: 10;
          content: '';
          height: 1px;
          right: 15px;
          left: 15px;
          background-color: ${headerBorder};
          margin-top: -15px;
        }
        position: relative;
        color: #544f40;
        font-size: 14px;
        line-height: 20px;
        padding: 15px 40px 15px 15px;
        z-index: 1;
        border-bottom: 1px solid ${headerBorder};
        .clamp-lines__button {
          position: absolute;
          border: none;
          background-color: transparent;
          right: 12px;
          top: 15px;
          width: 33px;
          :focus {
            outline: none;
          }
          img.reverse {
            transform: scaleY(-1);
          }
        }
      }
      @media (max-width: 767px) and (min-width: 320px) {
        .clamp-lines {
          padding-bottom: 25px;
          margin-bottom: -3px;
          ::before {
            display: none;
          }
        }
        .pivoted {
          padding-left: 0 !important;
        }
        .tdBefore {
          display: none;
        }
      }
    }
    .result-row {
      height: 98px;
      td {
        padding: 0 14px;
        font-size: 14px;
        line-height: 18px;
        color: ${fontColor};
        padding-top: 15px;
        vertical-align: top;
        &.condition-section {
          font-weight: bold;
          max-width: 220px;
          width: 22%;
          padding-top: 22px;
        }
        &.distance-section {
          max-width: 220px;
          width: 22%;
          padding-top: 18px;
        }
        &.status-section {
          line-height: 30px;
          .status-icons {
            align-items: center;
            display: flex;
            white-space: nowrap;
          }
          .nullValue::before {
            content: '';
            width: 25px;
            height: 25px;
            background-image: url(/icons/status_other.png);
            background-size: 25px;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 8px;
          }
          .recstatus_recruiting::before {
            content: '';
            width: 25px;
            height: 25px;
            background-image: url(/icons/status_recruiting.png);
            background-size: 25px;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 8px;
          }
          .recstatus_study_completed::before {
            content: '';
            width: 25px;
            height: 25px;
            background-image: url(/icons/status_completed.png);
            background-size: 25px;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 8px;
          }
          .recstatus_not_yet_recruiting::before {
            content: '';
            width: 25px;
            height: 25px;
            background-image: url(/icons/status_will_be_recruiting.png);
            background-size: 25px;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 8px;
          }
          .recstatus_not_recruiting::before {
            content: '';
            width: 25px;
            height: 25px;
            background-image: url(/icons/status_recruitment_completed.png);
            background-size: 25px;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 8px;
          }
          .recstatus_other::before {
            content: '';
            width: 25px;
            height: 25px;
            background-image: url(/icons/status_other.png);
            background-size: 25px;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 8px;
          }
        }
        &.details-section {
          min-width: 120px;
          width: 15%;
        }
        &.requirements-section {
          min-width: 170px;
          width: 23%;
          .ageRange {
            align-items: center;
            display: flex;
          }
          .female::before {
            content: '';
            width: 24px;
            height: 24px;
            background-image: url(/icons/gender_female.png);
            background-size: 24px;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 8px;
          }
          .male::before {
            content: '';
            width: 24px;
            height: 24px;
            background-image: url(/icons/gender_male.png);
            background-size: 24px;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 8px;
          }
          .both::before {
            content: '';
            width: 30px;
            height: 24px;
            background-image: url(/icons/gender_both.png);
            background-size: 24px;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 8px;
          }
          .null::before {
            content: '';
            width: 24px;
            height: 24px;
            background-image: url(/icons/gender_none.png);
            background-size: 24px;
            background-repeat: no-repeat;
            display: inline-block;
            margin-right: 8px;
          }
        }
      }
      &.row-unpaired {
        background-color: ${footerBGGrey};
      }
      .view-details {
        border: 1px solid ${buttonBG};
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
        line-height: 21px;
        text-align: center;
        color: ${white};
        background-color: ${buttonBG};
        padding: 4px 8px;
        display: block;
        max-height: 29px;
        white-space: nowrap;
        &:hover {
          text-decoration: none;
          background-color: ${blueHover};
        }
      }
      .contactUs {
        border: 1px solid ${buttonBG};
        border-radius: 4px;
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        text-align: center;
        color: ${buttonBG};
        background-color: transparent;
        padding: 4px 8px;
        display: block;
        width: 100%;
        margin-top: 10px;
        max-height: 29px;
        &:hover {
          text-decoration: none;
          color: ${blueHover};
        }
      }
    }
  }

  //Talet
  @media (min-width: 768px) and (max-width: 1024px) {
    .table-body {
      .result-row {
        td {
          &.condition-section {
            max-width: 180px;
          }
          &.distance-section {
            max-width: 140px;
          }
        }
      }
    }
  }

  //mobile landscape
  @media (min-width: 643px) and (max-width: 767px) and (orientation: landscape) {
    .table-body {
      .result-row {
        td.requirements-section {
          width: 23% !important;
          padding: 30px 5px 0 5px;
        }
      }
    }
  }

  //Mobile
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0px 0 0px 0;
    .table-header {
      border: none;
    }
    .table-body {
      .result-row {
        height: auto;
        border-bottom: 1px solid ${headerBorder};
        border-top: none;
        border-left: none;
        border-right: none;
        padding: 0;
        td {
          line-height: 18px;
          color: ${fontColor};
          padding-top: 30px;
          vertical-align: top;
          .tdBefore {
            svg {
              display: none;
            }
          }
          &.condition-section {
            max-width: 100%;
            line-height: 18px;
            padding-top: 30px;
            width: auto;
          }
          &.distance-section {
            max-width: 100%;
            padding-top: 32px;
            width: auto;
          }
          &.details-section {
            margin-bottom: 30px;
            max-width: 100%;
            width: auto;
            .contactUs {
              padding: 5px 0px;
            }
          }
          &.requirements-section {
            max-width: 100%;
            width: auto;
            .female::before {
              background-size: 21px;
            }
            .male::before {
              background-size: 21px;
            }
            .both::before {
              background-size: 21px;
            }
            .null::before {
              background-size: 21px;
            }
          }
          &.status-section {
            .status-icons {
              white-space: normal;
            }
            .nullValue::before {
              width: 30px;
            }
            .recstatus_recruiting::before {
              width: 30px;
              white-space: normal;
            }
            .recstatus_study_completed::before {
              width: 30px;
            }
            .recstatus_not_yet_recruiting::before {
              width: 32px;
            }
            .recstatus_not_recruiting::before {
              width: 34px;
              white-space: normal;
              line-height: 18px;
            }
            .recstatus_other::before {
              width: 30px;
            }
          }
        }
      }
      .result-second-row {
        padding: 0;
        border: none;
      }
    }
  }
`;
export const PaginationStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 135px;
  margin-bottom: 30px;
  &.bottom-pagination {
    margin-top: 30px !important;
    margin-bottom: 80px;
  }
  .results {
    color: ${fontColor};
    font-size: 16px;
    font-weight: normal;
    line-height: 21px;
  }
  .pagination {
    height: 35px;
    li {
      height: 35px;
      width: 35px;
      border-top: 1px solid ${headerBorder};
      border-bottom: 1px solid ${headerBorder};
      border-right: 1px solid ${headerBorder};
      &:focus {
        outline: none;
      }
      &.previous {
        border-radius: 4px 0 0 4px;
        border-left: 1px solid ${headerBorder};
        a {
          background-image: url(/icons/pagination-left.svg);
          background-position-x: center;
          background-repeat: no-repeat;
          background-size: 14px;
          background-position-y: center;
        }
        &:hover {
          a {
            background-image: url(/icons/pagination-left-hover.svg);
          }
        }
      }
      &.next {
        border-radius: 0 4px 4px 0;
        a {
          background-image: url(/icons/pagination-right.svg);
          background-position-x: center;
          background-repeat: no-repeat;
          background-size: 14px;
          background-position-y: center;
        }
        &:hover {
          a {
            background-image: url(/icons/pagination-right-hover.svg);
          }
        }
      }
      a {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        color: ${fontColor};
        font-size: 12px;
        line-height: 15px;
        &:focus {
          outline: none;
        }
      }
      &.disabled {
        &.next {
          background-color: ${white} !important;
          border: 1px solid ${headerBorder} !important;
          a {
            background-image: url(/icons/pagination-right.svg) !important;
            cursor: default;
          }
        }
        &.previous {
          background-color: ${white} !important;
          border: 1px solid ${headerBorder} !important;
          a {
            background-image: url(/icons/pagination-left.svg) !important;
            cursor: default;
          }
        }
      }
      &.active,
      &:hover {
        border: 1px solid ${activeColor};
        background-color: ${activeColor};
        a {
          color: ${white};
          font-size: 12px;
          line-height: 15px;
          text-decoration: none;
        }
      }
    }
  }
  //Tablet
  @media (min-width: 767px) and (max-width: 1024px) {
    margin-top: 30px;
    margin-bottom: 30px;
    &.bottom-pagination {
      margin-top: 30px;
    }
    .pagination {
      margin-bottom: 0;
    }
  }
  //Tablet landscape
  @media (min-width: 767px) and (max-width: 1024px) and (orientation: landscape) {
    margin-top: 30px;
    &.bottom-pagination {
      margin-top: 40px;
    }
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    li {
      width: 100%;
      min-width: 40px;
    }
    .results {
      padding-bottom: 10px;
    }
    &.bottom-pagination {
      margin-top: 20px !important;
      margin-bottom: 40px;
    }
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    .pagination {
      width: auto;
    }
  }
  //IPAD Pro
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    margin-top: 135px;
  }
`;
export const FindATrialstyle = styled.div`
  padding: 40px;
  &.results {
    padding: 40px 25px 40px 40px;
    h4 {
      color: ${fontColor};
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      margin-top: 0;
      padding: 0;
      margin-bottom: 10px;
      text-align: left;
    }
  }
  h4 {
    color: ${fontColor};
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    margin-top: 0;
    padding: 0;
    margin-bottom: 10px;
    text-align: left;
  }
  .moreSearch {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .resultsAction {
    width: 100%;
    padding-right: 0px;
  }
  .location-section {
    position: relative;
  }

  #distance,
  #keyword {
    position: absolute;
    margin-left: 5px;
    margin-top: -5px;
  }
  p {
    color: ${fontColor};
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    padding-bottom: 0px;
    margin-right: 15px;
    &.warning-message {
      display: flex;
      margin: 0;
      color: #f33333;
      padding-top: 10px;
      font-size: 14px;
    }
  }
  a {
    color: ${buttonBG};
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    svg {
      color: ${buttonBG};
      font-size: 22px;
      line-height: 22px;
      margin-left: 5px;
    }
    &:hover {
      text-decoration: none;
      color: ${blueHover};
    }
  }
  .radio-btn {
    margin-top: 10px;
  }
  .searchBTN {
    margin-top: 28px;
    height: 40px;
    width: 100%;
    background-color: ${activeColor};
    border: 1px solid ${activeColor};
    border-radius: 4px;
    color: ${white};
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: ${hoverColor};
      border: 1px solid ${hoverColor};
    }
  }
  .suggestions,
  .autocomplete-dropdown-container {
    list-style: none;
    position: absolute;
    right: 15px;
    left: 15px;
    padding-right: -30px;
    padding: 10px 16px;
    max-height: 250px;
    overflow: hidden;
    border-top: 0;
    margin: 0;
    z-index: 10;
    text-align: left;
    background-color: ${generalBgGrey};
    border: 1px solid ${headerBorder};
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
      0 10px 20px 0 rgba(0, 0, 0, 0.1);
    li,
    span {
      color: ${fontColor};
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      cursor: pointer;
      margin-bottom: 6px;
      :hover {
        color: ${activeColor};
      }
    }
    .google-powered {
      display: flex;
      justify-content: flex-end;
    }
  }
  input {
    width: 100%;
    height: 40px;
    border: 1px solid ${headerBorder};
    border-radius: 4px;
    font-size: 14px;
    padding: 8px 16px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${inputDark};
    &::placeholder {
      color: #21252980;
    }
    //XI EIkc*f
    @media all and (-ms-high-contrast: none) {
      &::placeholder {
        color: #21252980;
      }
    }
    &:focus {
      outline: none;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
        0 10px 20px 0 rgba(0, 0, 0, 0.1);
    }
    &.wrong {
      box-shadow: 0 0 4px 0 #f33333;
    }
  }
  .select-dropdown {
    button {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;
      width: 100%;
      height: 40px;
      border: 1px solid ${headerBorder};
      border-radius: 4px;
      background-color: ${white};
      color: ${inputDark};
      font-size: 14px;
      line-height: 24px;
      text-align: left;
      padding: 8px 24px 8px 16px;
      &:disabled {
        opacity: 0.5;
        img {
          opacity: 0.5;
        }
      }
      .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img {
        height: 10px;
        width: 17px;
        position: absolute;
        right: 8px;
        top: 16px;
      }
      @media all and (-ms-high-contrast: none) {
        img {
          top: 16px;
        }
      }
      p {
        font-size: 14px;
        line-height: 24px;
        color: #21252980;
        margin: 0;
      }
      &:focus,
      &:active {
        outline: none;
        border-radius: 3px 3px 0 0;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
          0 10px 20px 0 rgba(0, 0, 0, 0.1);
      }
    }
    .dropdown-menu {
      width: 100%;
      padding: 10px 16px;
      border-radius: 0 0 3px 3px;
      border-top: 0;
      margin: 0;
      background-color: #f9f9f9;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
        0 10px 20px 0 rgba(0, 0, 0, 0.1);
      .dropdown-item {
        color: #544f40;
        background-color: transparent;
        height: auto;
        border: none;
        padding: 0;
        :hover {
          color: ${activeColor};
        }
        &:active {
          box-shadow: none;
        }
      }
    }
  }
  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 40px;
    &.results {
      padding: 25px 25px 40px 40px;
    }
    .genderClass {
      text-align: left;
    }
    .location-section {
      padding-right: 0;
    }
    .distance-section {
      h4 {
        margin-top: 12px;
      }
    }
    .resultsAction {
      padding-right: 0px;
      padding-top: 12px;
    }
    .resultsLocation {
      padding-right: 0;
    }
    .moreSearch {
      justify-content: start;
      p {
        text-align: left;
      }
      a {
        text-align: left;
      }
    }
    .autocomplete-dropdown-container {
      right: 0;
    }
  }

  //TABLET Landscape
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    &.results {
      padding: 38px 25px 40px 40px;
    }
    .moreSearch {
      justify-content: flex-end;
    }
    .select-dropdown {
      button {
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          overflow: visible;
        }
      }
      .dropdown-menu {
        padding: 10px 8px !important;
      }
    }
    .resultsAction {
      padding-top: 0;
    }
    .distance-section {
      h4 {
        margin-top: 0px;
      }
    }
    .autocomplete-dropdown-container {
      right: 0;
    }
  }
  //IPAD Pro
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
    .resultsAction {
      padding-top: 0;
    }
    .distance-section {
      h4 {
        margin-top: 0px;
      }

      .select-dropdown {
        .dropdown-menu {
          padding: 10px 8px;
        }
        button {
          .ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .autocomplete-dropdown-container {
      right: 0;
    }
  }

  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    // .suggestions,
    // .autocomplete-dropdown-container {
    //   right: 15px;
    // }
  }

  //Mobile
  @media (min-width: 320px) and (max-width: 767px) {
    input {
      font-size: 16px;
    }
    .select-dropdown {
      button,
      .ellipsis p {
        font-size: 16px;
      }
    }
    p.warning-message {
      position: relative;
      display: flex;
      padding-left: 0;
      font-size: 14px;
    }
    padding: 30px 40px 40px 40px;
    &.results {
      padding: 20px 25px 40px 40px;
      .search-section {
        h4 {
          font-size: 14px;
          line-height: 19px;
          margin-bottom: 8px;
          margin-top: 20px;
        }
      }
    }
    .genderClass {
      text-align: left;
      .check {
        height: 30px !important;
        width: 30px !important;
        margin-right: 5px;
      }
    }
    .moreSearch {
      flex-direction: column;
      justify-content: left;
      text-align: left;
      order: 5;
      a {
        margin-top: 0;
      }
      p {
        margin-top: 30px;
      }
    }
    .keyword-section {
      order: 1;
      padding-right: 0;
    }
    .location-section {
      padding-right: 15px;
      order: 2;
      padding-right: 0;
      &.resultsLocation {
        padding-top: 0;
        order: 2;
        margin-top: 0;
      }
    }

    .resultsDistance {
      order: 3;
    }
    .resultsAction {
      order: 4;
      padding-right: 0px;
    }
    .action-section {
      order: 4;
      padding-top: 25px;
      .searchBTN {
        margin-top: 0;
      }
    }
    .distance-section {
      order: 3;
      padding-right: 0;
    }
    .status-section {
      order: 3;
    }
    .suggestions,
    .autocomplete-dropdown-container {
      right: 0;
    }
  }
  //Mobile landscape
  @media (max-width: 991px) and (orientation: landscape) {
    .distance-section {
      h4 {
        margin-top: 12px;
      }
    }
    .resultsAction {
      button {
        margin-top: 40px;
      }
    }
  }
`;

export const DetailsHero = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .last-updated-date p {    
      color: #fff;
      font-size: 16px;
      line-height: 24px;
  }
  .background-style {
    background: linear-gradient(45deg, #64564e 0%, #b9b3ae 100%);
    background-color: red;
    position: absolute;
    z-index: -1;
    height: 800px;
    left: 0;
    right: 0;
  }
  .mobile-share {
    display: none;
  }
  .data-display-row {
    margin-left: 0;
    margin-right: 0;
  }

  .details-title {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    line-height: 34px;
  }
  .media-row {
    margin-top: 9px;
  }
  .hero-sec {
    padding-left: 0;
  }
  .relin-text {
    color: #ffda00;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    word-break: break-word;
  }
  .hero-title-col {
    padding: 0 25px;
    position: relative;
    ::before {
      content: '';
      position: absolute;
      width: 5px;
      background-color: ${yellow};
      border-radius: 2.5px;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }
  .patient-data {
    border-left: 1px solid #ced4da;
    display: flex;
    justify-content: center;
    align-items: center;
  .patientLink {
    .patient-link {
      display: flex;
      border: 1px solid ${buttonBG};
      border-radius: 4px;
      background-color: ${buttonBG};
      line-height: 19px;
      font-size: 14px;
      font-weight: bold;
      max-width: 220px;
      color: ${white};
      padding: 4px 16px;
      text-align: center;
      &:hover {
        text-decoration: none;
        background-color: ${blueHover};
      }
    }
  }
}
  .modal-contact {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 10px;
    button {
      padding: 0;
      .divModal {
        padding: 8px 16px;
        background-color: #f36533;
        border-radius: 4px;
        cursor: pointer;
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        line-height: 24px;
        font-weight: bold;
        &:hover {
          background-color: ${hoverColor};
        }
      }
    }
  }
  .status-display {
    color: #ffffff;
    font-size: 19px;
    line-height: 25px;
    margin-top: 17px;
    display: flex;
    align-items: center;
  }
  .go-back-button {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 18px;
    background-color: transparent;
    border: 0;
    color: ${white};
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    margin-left: 0px;
    svg {
      height: 24px;
      margin-right: 5px;
    }
    &:hover {
      color: ${activeColor};
    }
    &:focus {
      outline: none;
    }
  }
  .details-page {
    max-width: 1110px;
    z-index: 1;
    margin-bottom: 50px;
    &::after {
      margin-top: -40px;
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      content: '';
      background-color: #fff;
      height: 95px;
    }
  }
  .details-page-with-video {
    max-width: 1110px;
    z-index: 1;
    margin-bottom: 50px;
    &::after {
      margin-top: -726px;
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      content: '';
      background-color: #fff;
      height: 600px;
    }
  }
  .recstatus_recruiting::before {
    content: '';
    background-image: url(/icons/status_recruiting.png);
    background-size: 25px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 8px;
    background-position-x: center;
    background-position-y: center;
    width: 30px;
    height: 30px;
    background-color: ${white};
    border-radius: 50%;
  }
  .recstatus_study_completed::before {
    content: '';
    background-position-x: center;
    background-position-y: center;
    width: 30px;
    height: 30px;
    background-color: ${white};
    border-radius: 50%;
    background-image: url(/icons/status_completed.png);
    background-size: 25px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 8px;
  }
  .recstatus_not_yet_recruiting::before {
    content: '';
    background-position-x: center;
    background-position-y: center;
    width: 30px;
    height: 30px;
    background-color: ${white};
    border-radius: 50%;
    background-image: url(/icons/status_will_be_recruiting.png);
    background-size: 25px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 8px;
  }
  .recstatus_not_recruiting::before {
    content: '';
    background-position-x: center;
    background-position-y: center;
    width: 30px;
    height: 30px;
    background-color: ${white};
    border-radius: 50%;
    background-image: url(/icons/status_recruitment_completed.png);
    background-size: 25px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 8px;
  }
  .recstatus_other::before {
    content: '';
    background-position-x: center;
    background-position-y: center;
    width: 30px;
    height: 30px;
    background-color: ${white};
    border-radius: 50%;
    background-image: url(/icons/status_other.png);
    background-size: 25px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 8px;
  }
  .info-study {
    display: flex;
    padding: 40px;
    width: 100%;
    margin-top: 40px;
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
      0 30px 40px 0 rgba(0, 0, 0, 0.2);
    .id-cols {
      border-left: 1px solid #ced4da;
      &.desk-id {
        border-left: 0;
      }
      h3 {
        color: #544f40;
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 5px;
      }
      img {
        position: absolute;
        margin-top: -6px;
        margin-left: 3px;
      }
      p {
        color: #544f40;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }
      a {
        color: ${activeColor};
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        :hover {
          color: ${hoverColor};
          text-decoration: none;
        }
        &.doc-info {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 18px;
          color: #15717d;
          font-size: 12px;
          line-height: 16px;
          img {
            height: 20px;
            width: 15px;
            position: relative;
            margin-right: 7px;
            margin-left: 0;
            margin-top: 0;
          }
          &:hover {
            color: #105c66;
          }
        }
      }
    }
  }
  

.info-study-inner {
  display: flex;
}

.info-study-col {
  flex: 1;
}

@media (max-width: 1080px) {
  .info-study-col {
    width: 50%;
    flex: auto;
  }

  .info-study-inner {
    flex-wrap: wrap;
    row-gap: 20px;
  }
}

@media (max-width: 767px) {
  .info-study-inner {
    flex-direction: column;
  }
  .info-study-col {
    width: 100%;
  }
}
  //TABLET
  @media (min-width: 767px) and (max-width: 991px) {
    .tablet-border {
      padding-bottom: 30px;
    }
    .tablet-noborder {
      border: 0 !important;
    }
    .border-corection {
      border-left: 1px solid #ced4da;
    }
    .details-page-with-video {
      max-width: 1110px;
      z-index: 1;
      margin-bottom: 50px;
      &::after {
        margin-top: -532px;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        content: '';
        background-color: #fff;
        height: 600px;
      }
    }
  }

  //Mobile
  @media (min-width: 320px) and (max-width: 766px) {
    .modal-contact {
      button {
        .divModal {
          font-size: 14px;
          line-height: 22px;
          padding: 4px 16px;
          &:hover {
            background-color: ${hoverColor};
          }
        }
      }
    }
    .background-style {
      background: linear-gradient(45deg, #64564e 0%, #b9b3ae 100%);
      background-color: #64564e;
      height: 1400px;
    }
    .border-corection {
      border-bottom: 0 !important;
    }
    .patient-data {
      margin-top: 20px;
      border-left: none;
    }
    .top-navigation-row {
      padding-top: 5px;
    }
    .info-study {
      display: block;
      padding: 20px 40px 40px 40px;
      .row {
        width: auto;
        margin: 0;
      }
      .id-cols {
        padding: 20px 0;
        border-left: none;
        border-bottom: 1px solid #ced4da;
      }
      .modal-contact {
        padding-top: 20px;
      }
    }


    .details-title {
      font-size: 23px;
      line-height: 30px;
      &.big-element {
        font-size: 18px;
        line-height: 25px;
      }
    }
    .relin-text { 
      margin-top: 55px;
    }
    .go-back-button {
      .go-back-btn-text {
        display: none;
      }
    }
    .mobile-share {
      padding: 0;
      display: block;
      background-color: transparent;
      border: none;
      .shareSvgPlaceholder {
        display: inline-block;
        background-color: ${activeColor};
        padding: 8px;
        border-radius: 50%;
        order: 2;
        .mobileShare-btn {
          display: block;
          color: ${white};
          font-size: 21px;
          background-color: ${activeColor};
          border: 1px solid ${activeColor};
        }
      }
      p {           font-size: 16px;
        font-weight: bold;
        line-height: 32px;
        font-size: 16px;
        color: ${white};
        display: inline-block;
        padding-right: 10px;
        vertical-align: top;
        order:1;
      }
      &.open {
        display: flex;
      }
      &.closed {
        display: none;
      }
      &:focus {
        outline-none;
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .data-display-row {
      position: relative;
      padding-top: 5px;
      .desktop-social {
        position: absolute;
        padding: 0;
        top: -46px;
        right: 0px;
        width: auto;
      }
    }
    .details-page-with-video {
      margin-bottom: 50px;
      &::after {
        margin-top: -327px;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        content: '';
        background-color: #fff;
        height: 377px;
      }
    }
    .details-page {
      margin-bottom: 40px;
        &::after {
        height: 80px;
      }
      .last-updated-date {
         margin: 15px 0px 0px 25px;
      }
    }
  }
`;
export const TrialPurposeStyle = styled.div``;
export const TrialSummaryStyle = styled.div`
  .shadow-back {
    position: absolute;
    left: -50vw;
    right: -50vw;
    height: 100%;
    margin-top: -40px;
    bottom: 40px;
    background-color: #f5f5f5;
  }
  .fonts-cont {
    padding: 0;
  }
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .trial-summary-row {
    position: relative;
    margin: 40px 0 0 0;
    padding: 0 0 40px 0;
    margin-left: 0;
    margin-right: 0;
    .bordered {
      border-bottom: 1px solid #ced4da;
    }
    .cards-col {
      padding: 0;
    }
    .card {
      border: 0;
      background-color: transparent;
      .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 40px 10px;
        img {
          height: 56px;
          width: 56px;
        }
        h3 {
          color: #544f40;
          font-size: 14px;
          font-weight: bold;
          line-height: 18px;
          margin-top: 20px;
          margin-bottom: 5px;
          img {
            position: absolute;
            margin-top: -6px;
            margin-left: 3px;
            height: 14px;
            width: 14px;
          }
        }
        h4 {
          color: #544f40;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          flex-wrap: wrap;
          width: 100%;
          font-weight: 300;
        }
      }
    }
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 766px) {
    h2 {
      text-align: center;
    }
    .trial-summary-row {

    .bordered {
      border-bottom: none;
    }
    .end {
       border-bottom: 1px solid #ced4da;
    }
  }
`;
export const MapSectionStyle = styled.div`
  @media print {
    .gm-style div > img {
      position: absolute;
    }
    .collapse {
      display: block;
    }
    .print-contact {
      display: block !important;
    }
    .modal-status {
      button {
        display: none;
      }
    }
    table {
      &.scroll {
        tbody {
          max-height: initial !important;
        }
      }
    }
  }
  .message-box {
    height: 255px !important;
  }
  .map-section {
    padding-left: 0;
    padding-right: 0;
  }
  .mapSection {
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    padding: 0;
    .fonts-cont {
      padding: 0;
    }
  }
  p.missing {
    padding: 0 30px 40px 30px;
    color: #544f40;
    font-size: 16px;
    line-height: 22px;
  }
  h2 {
    color: #f36533;
    font-size: 21px;
    font-weight: bold;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    cursor: pointer;
    img {
      height: 16px;
      width: 16px;
    }
    .arrow-position {
      transform: scaleY(-1);
    }
  }
  .location-holder {
    margin: 0 20px 40px 20px;
  }
  .map-locations {
    margin: 40px 20px 30px 20px;
    height: 400px;
    .marker-info-window {
      line-height: 16px;
      strong {
        font-weight: 900;
        line-height: 20px;
      }
      a {
        color: ${activeColor};
        &:hover {
          color: ${hoverColor};
          text-decoration: none;
        }
      }
    }
  }
  table {
    &.scroll {
      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
      tbody {
        display: block;
        max-height: 600px;
        height: 100%;
        overflow: auto;
      }
    }
    thead {
      border-bottom: 1px solid #f36533;
      border-top: 1px solid #ced4da;
      border-right: 1px solid #ced4da;
      border-left: 1px solid #ced4da;
      tr {
        height: 71px;
        th {
          color: ${fontColor};
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
          &.first {
            padding-left: 15px;
            padding-top: 16px;
          }
          &.last {
            text-align: center;
            vertical-align: middle;
          }
        }
      }
    }
    tbody {
      tr {
        height: 60px;
        border-bottom: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
        border-left: 1px solid #ced4da;
        &.row-unpaired {
          background-color: #f5f5f5;
        }
        td {
          .print-contact {
            display: none;
          }
          &.location {
            padding-top: 15px;
            padding-left: 15px;
            color: #544f40;
            font-size: 14px;
            font-weight: bold;
            line-height: 19px;
          }
          &.modal-status {
            text-align: center;
            vertical-align: middle;
            button {
              padding: 0;
              .divModal {
                color: #ffffff;
                font-size: 14px;
                font-weight: bold;
                line-height: 21px;
                text-align: center;
                background-color: #15717d;
                border-radius: 4px;
                padding: 4px 28px;
              }
            }
          }
          &.status-line {
            color: #544f40;
            font-size: 14px;
            line-height: 19px;
            .locstatus_recruiting::before {
              content: '';
              width: 25px;
              height: 25px;
              background-image: url(/icons/status_recruiting.png);
              background-size: 25px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 8px;
              margin-bottom: -12px;
            }
            .locstatus_completed::before {
              content: '';
              width: 25px;
              height: 25px;
              background-image: url(/icons/status_completed.png);
              background-size: 25px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 8px;
              margin-bottom: -12px;
            }
            .locstatus_not_yet_rec::before {
              content: '';
              width: 25px;
              height: 25px;
              background-image: url(/icons/status_will_be_recruiting.png);
              background-size: 25px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 8px;
              margin-bottom: -12px;
            }
            .locstatus_active_not_rec::before {
              content: '';
              width: 25px;
              height: 25px;
              background-image: url(/icons/status_recruitment_completed.png);
              background-size: 25px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 8px;
              margin-bottom: -12px;
            }
            .locstatus_other,
            .locstatus_terminated::before {
              content: '';
              width: 25px;
              height: 25px;
              background-image: url(/icons/status_other.png);
              background-size: 25px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 8px;
              margin-bottom: -12px;
            }
          }
        }
      }
    }
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 767px) {
  }

  @media screen and (max-width: 40em) {
    .location-holder {
      border: 1px solid ${headerBorder};
      border-radius: 4px;
    }
    thead {
      border: none !important;
    }
    tbody {
      border: none;
      tr {
        height: auto !important;
        border: none !important;
        border-bottom: 1px solid ${headerBorder} !important;
        td {
          padding-top: 30px;
          padding-right: 15px;
          font-size: 14px;
          line-height: 18px;
          &.location {
            padding-top: 30px !important;
          }
          &.modal-status {
            padding-bottom: 30px;
            button {
              white-space: nowrap;
              width: 100%;
              .divModal {
                width: auto;
                padding: 4px 0;
              }
            }
          }
          &.pivoted {
            padding-left: calc(50%) !important;
            text-align: left !important;
          }
        }
      }
    }
  }
`;
export const ProtocolSummary = styled.div`
  @media print {
    .collapse {
      display: block;
    }
    .print-criteria {
      display: block !important;
      pre {
        page-break-inside: auto;
      }
    }
  }
  .print-criteria {
    display: none;
    h2 {
      color: ${fontColor};
      font-size: 21px;
      margin: 20px 0;
      font-weight: bold;
      line-height: 30px;
      padding: 0;
    }
    pre {
      color: ${fontColor};
      font-size: 16px;
      line-height: 24px;
      border: none;
      white-space: pre-line;
      padding: 0;
      font-family: inherit;
      font-weight: 300;
      margin: 0px;
      padding-bottom: 10px;
      &.second-pre {
        padding-bottom: 20px;
      }
    }
  }
  border-bottom: 1px solid #ced4da;
  h2 {
    color: #f36533;
    font-size: 21px;
    font-weight: bold;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    cursor: pointer;
    img {
      height: 16px;
      width: 16px;
    }
    .arrow-position {
      transform: scaleY(-1);
    }
    &.sub-ordered-collapse {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .protocol-collapse {
    padding: 40px 30px 40px 30px;
    .protocol-title {
      color: ${fontColor};
      font-size: 21px;
      font-weight: bold;
      line-height: 27px;
    }
    .protocol-description {
      color: ${fontColor};
      font-size: 16px;
      line-height: 24px;
      padding: 20px 0 25px 0;
    }
    .section-holder {
      &.citations {
        display: flex;
      }
      flex-direction: row;
      h4 {
        flex-direction: row;
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
        color: ${fontColor};
        display: inline-flex;
        min-width: 240px;
        padding: 5px 0;
        &.displayed-data {
          font-weight: normal;
          &.citation {
            display: block;
          }
        }
        .tooltip-img {
          margin-top: -6px;
          margin-left: 5px;
          height: 14px;
          width: 14px;
        }
        ul {
          li {
            list-style: none;
          }
        }
      }
    }
  }
  .outcomes-holder {
    padding: 40px;
    li {
      font-size: 16px;
      line-height: 22px;
      color: ${fontColor};
    }
    .not-available {
      font-size: 16px;
      line-height: 22px;
      color: ${fontColor};
    }
  }
  .collapse-holder {
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    &.closed {
      border-bottom: none;
    }
    &.open {
      border-bottom: none;
    }
    &.primary {
      margin-top: 30px;
    }
    &.secondary {
      margin-bottom: 30px;
    }
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 766px) {
    .protocol-collapse {
      padding: 40px 15px 30px 15px;
      .section-holder {
        h4 {
          padding-top: 5px;
          padding-bottom: 0px;
          &.displayed-data {
            padding-bottom: 10px;
            padding-top: 0px;
          }
        }
      }
    }
    .collapse-holder {
      &.primary {
        margin-top: 20px;
      }
    }
  }
  @media (max-width: 768px) {
    .citations {
      display: block !important;
    }
  }
`;
export const DocumentsSection = styled.div`
  border-bottom: 1px solid #ced4da;
  margin-bottom: 40px;
  h2 {
    color: #f36533;
    font-size: 21px;
    font-weight: bold;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    cursor: pointer;
    img {
      height: 16px;
      width: 16px;
    }
    .arrow-position {
      transform: scaleY(-1);
    }
  }
  .document-sec {
    padding: 30px 0;
  }
  p {
    padding: 10px 0;
    color: #544f40;
    font-size: 16px;
    line-height: 22px;
    margin-left: 40px;
  }
  .documents-holder {
    padding: 10px 0 10px 30px;
    display: inline-flex;
    a {
      display: flex;
      align-items: center;
      background-color: #15717d;
      border-radius: 4px;
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      padding: 11px 16px;
      img {
        height: 16px;
        width: 16px;
        margin-right: 7px;
        margin-bottom: -2px;
      }
      &:hover {
        background-color: #105c66;
        text-decoration: none;
      }
    }
  }
  @media (max-width: 374px) {
    .documents-holder {
      a {
        font-size: 12px;
      }
    }
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 768px) {
    .no-doc-message {
      padding-left: 15px;
    }
    p {
      margin-left: 0;
    }
    .documents-holder {
      padding-left: 15px;
    }
  }
`;
export const BackButton = styled.div`
  margin-bottom: 40px;
  button {
    border: none;
    background-color: transparent;
    color: #15717d;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    svg {
      height: 24px;
      margin-right: 5px;
    }
    &:hover {
      color: #105c66;
      text-decoration: none;
    }
    &:focus {
      outline: none;
    }
  }
`;

export const StudyNotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 40px;
    line-height: 48px;
    margin: 40px 0 !important;
    text-align: center;
    color: #f36533;
  }
  a {
    color: #f36533;
  }
  a:hover {
    color: #cf4e20;
  }
  p {
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 24px;
  }
  @media (max-width: 767px) {
    p {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    min-height: 100px;
    h1.main-title {
      font-size: 32px;
    }
  }
`;

export const WistiaVideoStyle = styled.div`
  display: block;
  padding: 32px;
  width: 100%;
  margin-top: 40px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 30px 40px 0 rgba(0, 0, 0, 0.2);
  @media (max-width: 480px) {
    padding: 16px;
  }
`;
