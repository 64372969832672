import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { BactTop } from './back-to-top.css';
import PropTypes from 'prop-types';

const BackToTop = light => {
  return (
    <BactTop>
      <ScrollToTop
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: '30px',
          zIndex: '200',
        }}
        showUnder={300}
      >
        <img
          className={light ? 'special' : ''}
          src="/icons/back-to-top.svg"
          alt={'up'}
        />
        <span
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#544F40',
          }}
        >
          {'Back to Top'}
        </span>
      </ScrollToTop>
    </BactTop>
  );
};

export default BackToTop;

BackToTop.proptypes = {
  light: PropTypes.bool,
};
