import styled from 'styled-components';
import { white, fontColor, headerBorder, activeColor, hoverColor} from '../../constants/theme';

export const ContainerHeader = styled.header`
@media print {
  display: none;
}
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  position: fixed;
  z-index: 1000;
  background-color: ${white};
  border-bottom: 1px solid #F36533;
  width: 100%;
  .header-menu {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    background-color: ${white};
    .icon-holder {
      justify-content: left;
      display: flex;
      z-index: 999;
      .header-container {
        width: 100%;
      }
    }
    .menu-list {
      padding: 0 0;
      justify-content: flex-end;
      background-color: ${white};
      display: flex;
      .header-container {
        width: 100%;
      }
      &.desktop {
        display: flex;
        a {
          color: ${fontColor};
          text-transform: capitalized;
          font-size: 16px;
          line-height: 24px;
          text-decoration: none;
          padding: 18px 15px 21px 15px;
          svg {
            font-size: 24px;
            color: #544F40;
            &.contact-us-svg {
              font-size: 16px;
              margin-right: 5px;
            }
            &:hover {
              color: ${hoverColor};
            }
            &.active {
              color: ${activeColor};
            }
          }
          &.top-menu-links {
            font-size: 12px;
            line-height: 16px;
            padding: 16px 5px 6px 5px;
          }
          &.top-menu-links::after {
            content: '|';
            background-size: 16px;
            color: ${headerBorder};
            background-repeat: no-repeat;
            display: inline-block;
            margin: 0 10px 0 20px;
            font-size: 16px;
          }
          &.last::after {
            content: ' ';
            margin: 0 0 0 0;
          }
          &.last {
            padding-top: 13px;
            padding-right: 20px;
            button {
              padding-top: 0;
              color: ${fontColor};
              font-size: 12px;
              line-height: 16px;
            }
          }
          &.last::before {
            content: '';
            background-image: url(/icons/contact-us-icon.png);
            background-repeat: no-repeat;
            background-size: 100%;
            width: 16px;
            height: 16px;
            display: inline-block;
            margin-right: 5px;
          }
          &:hover {
            color: ${hoverColor};
          }
          &.active {
            color: ${activeColor};
          }
          &.last-item {
            padding-right: 20px;
          }
        }
      }
      &.mobile {
        display: none;
      }
    }
  }
  .logo-img {
    position: absolute;
    top: 35px;
    .gatsby-image-wrapper {
      overflow: visible !important;
    }
    img {
      width: auto !important;
      height: 40px !important;
    }
    .gatsby-image-wrapper {
      & > img {
        display: none;
      }
    }
  }
  
  @media (max-width: 991px) {
    justify-content: space-between;
    .header-menu-row {
      position: absolute;
      width: 100%;
    }
  }
  @media (min-width: 320px) and (max-width: 992px) {
  background-color: ${white};
  border-bottom: none;
    .header-menu {
      margin-left: 20px;
      margin-right: 20px;
      border-bottom: 1px solid #F36533;
      .menu-list {
        padding: 0 0;
        &.mobile {
          display: flex;
          a {
            color: ${fontColor};
            text-transform: capitalized;
            font-size: 16px;
            line-height: 24px;
            text-decoration: none;
            padding: 10px 20px;
            margin-left: 20px;
            svg {
              font-size: 24px;
              color: #544F40;
              &.contact-us-svg {
                font-size: 16px;
                margin-right: 5px;
              }
              &:hover {
                color: ${hoverColor};
              }
              &.active {
                color: ${activeColor};
              }
            }
            &.active {
              color: ${activeColor};
            }
            &:hover {
              color: ${fontColor};
            }
            &.last-item {
            border-bottom: 1px solid ${headerBorder};
            padding: 10px 0 20px 0;
            margin: 0 40px 10px 40px;
            }
            &.last {
              padding-right: 0px;
              button {
                color: ${fontColor};
              }
            }
            &.last::before {
              content: '';
              background-image: url(/icons/contact-us-icon.png);
              background-repeat: no-repeat;
              background-size: 100%;
              width: 16px;
              height: 16px;
              display: inline-block;
              margin-right: 5px;
            }
          }
        }
        &.desktop {
          display: none;
        }
      }
      .top-menu {
        min-height: 50px;
      }
    }
    .logo-img {
      position: absolute;
      top: 10px;
      left: 20px;
      padding: 0;
    }
  }
  //mobile changes
  @media (min-width: 320px) and (max-width: 992px) {
    .navbar-collapse {
      padding: 100px 0px 10px 0px !important;
      background-color: ${white};
      margin-top:-70px;
    }
  }

  //TABLET LANDSCAPE
  @media (min-width: 776px) and (max-width: 1024px) and (orientation: landscape) {
    .logo-img {
      margin-left: 0px;
    }
    .header-menu {
      .menu-list {
        &.desktop
          a {
            &:hover {
              color: ${fontColor};
            }
          }
        }
      }  
    }
  }

  //IPAD PRO
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .logo-img {
      margin-left: 0px;
    }
    .header-menu {
      .menu-list {
        &.desktop
          a {
            &:hover {
              color: ${fontColor};
            }
          }
        }
      }  
    }
  }
`;
