import styled from 'styled-components';
import {
  fontColor,
  hoverColor,
  white,
  activeColor,
  footerBGGrey,
  headerBorder,
} from '../../constants/theme';

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  background-color: ${white};
  padding: 0;
  background-color: ${footerBGGrey};
  border-top: 1px solid ${activeColor};
  .rights {
    display: flex;
    flex-direction: column;
    margin-left: 9.3%;
    margin-bottom: 30px;
    p {
      font-size: 12px;
      line-height: 18px;
      color: ${fontColor};
    }
  }
  .footer-content {
    max-width: 1140px;
    height: 100%;
    width: 100%;
    margin: 80px 20px;
    padding-top: 20px;
  }
  .icon-section {
    padding: 0;
    a {
      padding: 0 0 0 0;
      margin-left: 20px;
      width: 80px;
      height: 40px;
      z-index: 9;
    }
  }
  .social-section {
    text-align: right;
    white-space: nowrap;
    .icon {
      padding: 0 8px;
      text-align: right;
      img {
        width: 27px;
        height: 26px;
      }
    }
    .power-section {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 60px;
      font-size: 12px;
      line-height: 16px;
      color: ${fontColor};
      padding-right: 8px;
      img {
        max-height: 56px;
      }
      p {
        margin-right: 8px;
      }
    }
  }

  .nav-section {
    padding-bottom: 30px;
  }
  .row {
    width: 100%;
    margin: 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    background-color: ${white};
    border-top: none;
    &.special {
      margin-bottom: 60px;
    }
    .rights {
      margin-left: 0;
      p {
        text-align: center;
      }
    }
    .footer-content {
      margin: 0 20px;
      max-height: 1000px;
      background-color: ${footerBGGrey};
      border-top: 1px solid ${activeColor};
      .icon-section {
        padding: 0 0 14px 0;
        a {
          margin-left: 20px;
          width: 88px;
          height: 50px;
        }
      }
      .social-section {
        text-align: center;
        border-top: 1px solid ${headerBorder};
        padding: 30px 0 0 0;
        margin: 0 0px;
        .icon {
          padding-left: 4%;
          padding-right: 4%;

          &.instagram {
            padding-right: 0%;
          }
          &.facebook {
            padding-left: 0%;
          }
        }
        .power-section {
          padding-right: 0;
          margin: 30px 0;
          justify-content: center;
        }
      }
      .nav-section {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 14px;
        .nav-groups {
          padding: 11px 20px;
        }
      }
    }

    .nav-social {
      border-bottom: 1px solid #e1e1e1;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    .footer-content {
      .social-section {
        text-align: center;
        border-top: 1px solid ${headerBorder};
        padding: 30px 0px 0 0px;
        margin: 0 0;
        white-space: nowrap;
      }
    }
  }
  @media (min-width: 766px) and (max-width: 1024px) {
    background-color: ${white};
    border-top: none;
    .footer-content {
      margin: 0 20px;
      max-height: 1000px;
      background-color: ${footerBGGrey};
      border-top: 1px solid ${activeColor};
      .icon-section {
        a {
          margin-left: 10px;
          width: 70px;
          height: 40px;
        }
      }
      .social-section {
        text-align: center;
        border-top: 1px solid ${headerBorder};
        padding: 30px 0 0 0;
        margin: 0 0px;
        .icon {
          padding-left: 12px;
          padding-right: 12px;
          &.instagram {
            padding-right: 0%;
          }
          &.facebook {
            padding-left: 0%;
          }
        }
        .power-section {
          padding-right: 0;
          justify-content: center;
          margin: 30px 0;
        }
      }
    }
  }
  @media (min-width: 766px) and (max-width: 1024px) and (orientation: landscape) {
    background-color: ${footerBGGrey};
    border-top: 1px solid ${activeColor};
    .footer-content {
      max-height: 1000px;
      background-color: ${footerBGGrey};
      border-top: none;
      .icon-section {
        flex: 0 0 8%;
        max-width: 8%;
        a {
          margin-left: 0px;
          width: 77px;
          height: 40px;
        }
      }
      .social-section {
        text-align: right;
        border-top: none;
        .icon {
          padding-left: 12px;
          padding-right: 12px;
          &.instagram {
            padding-right: 0%;
          }
          &.facebook {
            padding-left: 0%;
          }
        }
        .power-section {
          padding-right: 0;
          justify-content: flex-end;
          margin: 60px 0 30px 0;
        }
      }
      .nav-section {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 14px;
        .nav-groups {
          padding: 11px 20px;
        }
      }
    }
  }
  //IPAD PRO Portrait
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
    .footer-content {
      .icon-section {
        a {
          margin-left: 20px;
        }
      }
      .social-section {
        text-align: right;
        border-top: none;
        .icon {
          padding-left: 12px;
          padding-right: 12px;
          &.instagram {
            padding-right: 20px;
          }
          &.facebook {
            padding-left: 0%;
          }
        }
        .power-section {
          padding-right: 20px;
          justify-content: flex-end;
        }
      }
    }
  }
  //Iphone X
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .footer-content {
      .icon-section {
        a {
          margin-left: 20px;
        }
      }
      .social-section {
        text-align: center;
        border-top: 1px solid ${headerBorder};
        padding: 30px 0 0 0;
        .power-section {
          padding-right: 0;
          justify-content: center;
          margin: 30px 0;
        }
      }
    }
  }
  @media (max-width: 550px) {
    .rights {
      margin-bottom: 60px;
    }
  }
`;

export const NavSection = styled.div`
  width: 100%;
  display: flex;
  width: 100%;
  justify-content: space-around;
  background-color: ${white};
  a {
    color: ${fontColor};
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    &:hover {
      color: ${hoverColor};
    }
    &.active {
      color: ${activeColor};
    }
  }
  .nav-link {
    padding: 0;
    &:hover {
      text-decoration: none;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    a {
      &:hover {
        color: ${fontColor};
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    a {
      &:hover {
        color: ${fontColor};
      }
    }
  }
  //IPAD PRO LANDSCAPE
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    a {
      &:hover {
        color: ${fontColor};
      }
    }
  }
`;
