import styled from 'styled-components';
import {activeColor, buttonBG, fontColor, hoverColor} from "../../constants/theme";


export const ContactUsContent = styled.div`
  padding: 0px 0px 20px 0px;
  h2 {
    color: ${fontColor};
    font-size: 28px;
    font-weight: bold;
    line-height: 36px;
  }
  h3 {
    color: ${fontColor};
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
  }
  .link-holder {
    color: ${fontColor};;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
    a {
      color: ${buttonBG};
    }
    &.top {
      margin-bottom: 20px;
    }
    &.bottom {
      margin-top: 5px;
    }
  }
  svg {
    color: ${activeColor} !important;
    height: 24px;
    width: 24px;
    &:hover {
      color: ${hoverColor} !important;
    }
  }
  .close-icon {
    width: 100%;
    display: inline-block;
    margin-top: 0;
  }
  .content-modal {
    padding: 0 20px;
  }
`;
