import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { ContactUsContent } from './contact-us-header.css';
import { FaTimesCircle } from 'react-icons/fa';
import { trackAnalytics } from '../../helpers/trackAnalytics';

class Disclaimer extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);    
    trackAnalytics('ContactUsShow', {
      title: 'Contact GSK',
      TrialId: '',
      TrialTitle: '',
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }
  render() {
    let { hideModal } = this.props;
    return (
      <ContactUsContent>
        <div className={'close-icon'}>
          <FaTimesCircle
            onClick={() => hideModal()}
            style={{
              fontSize: '30px',
              float: 'right',
              cursor: 'pointer',
              color: '#757575',
            }}
          />
        </div>
        <div className={'content-modal'}>
          <h2>Contact GSK</h2>
          <div className={'link-holder top'}>Contact us with any general enquiries about GSK Clinical Studies.</div>
          <h3>Phone:</h3>
          <div className={'link-holder bottom'}>Toll free international calling numbers:&nbsp;
            <a href={'http://www.clinicalsupporthd.gsk.com/contact.html'}
               target="_blank"
               rel="noopener noreferrer">View Numbers
            </a>
          </div>
        </div>
      </ContactUsContent>
    );
  }
}

Disclaimer.propTypes = {
  data: PropTypes.object.isRequired,
  hideModal: PropTypes.func,
};

const DisclaimerQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        homeJson {
          disclaimer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={data => <Disclaimer data={data} {...props} />}
  />
);

export default DisclaimerQuery;
