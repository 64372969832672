import styled from 'styled-components';
import { Dialog } from '@reach/dialog';
import { footerBGGrey, white, buttonBG, blueHover } from '../../constants/theme';

export const ConditionsModalDialog = styled(Dialog)`
  box-sizing: border-box;
  height: auto;
  width: 40vw !important;
  border: 1px solid ${footerBGGrey};
  border-radius: 16px;
  background-color: ${white};
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05), 0 30px 40px 0 rgba(0,0,0,0.2);
  margin: 30vh auto !important; 
  &.contactUs {
    width: 30vw !important;
    z-index: 888;
    margin: 15vh auto !important; 
  }
  &.criteria {
    width: 60vw !important;
    z-index: 888;
    margin: 15vh auto !important; 
  }
  &.contactUsHeader {
    width: 40vw !important;
    z-index: 888;
    margin: 15vh auto !important; 
  }
  
  //IPAD Pro
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    &.contactUs {
      width: 40vw !important;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 80vw !important;
    &.contactUs {
      width: 60vw !important;
      margin: 15vh auto !important;
    }
    &.criteria {
      width: 70vw !important;
      z-index: 888;
      margin: 10vh auto !important; 
    }
    &.contactUsHeader {
      width: 70vw !important;
      z-index: 888;
      margin: 15vh auto !important; 
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    width: 60vw !important;
    margin: 15vh auto !important;
    &.criteria {
      margin: 15vh auto !important;
    }
    &.contactUsHeader {
      width: 60vw !important;
      z-index: 888;
      margin: 17vh auto !important; 
    }
    &.contactUs{
      margin: 22vh auto !important;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    width: 90vw !important;
    margin-top: 110px !important;
    &.contactUs {
      width: 90vw !important;
      margin: 15vh auto !important;
    }
    &.criteria {
      width: 90vw !important;
      margin: 15vh auto !important;
    }
    &.contactUsHeader {
      width: 90vw !important;
      z-index: 888;
      margin: 15vh auto !important; 
    }
  }
  
  @media (min-width: 320px) and (max-width: 767px) and (orientation: landscape){
    &.criteria {
      width: 90vw !important;
      margin: 25vh auto !important;
    }
    &.contactUsHeader {
      width: 90vw !important;
      z-index: 888;
      margin: 25vh auto !important; 
    }
    &.contactUs {
      margin: 22vh auto !important;
    }
  }
`;

export const Button = styled.button`
  -webkit-appearance: none;
  color: ${buttonBG};
  border: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 0 0 0;
  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${blueHover};
    text-decoration: none;
  }
  svg {
    float: right;
    margin-left: 10px;
    color: ${buttonBG};
    font-size: 22px;
  }

  & + & {
    margin-left: 1rem;
  }
`;

export const Close = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  font-weight: normal;
  padding: 1rem;
  line-height: 4rem;
  position: absolute;
  top: 0;
  right: 0;
  &:focus {
    outline: none;
  }
`;
