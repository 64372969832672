import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Close, ConditionsModalDialog } from './modal.css';
import VisuallyHidden from '@reach/visually-hidden';
import '@reach/dialog/styles.css';
import { IoIosArrowDown } from 'react-icons/io';
import { FaExternalLinkAlt } from "react-icons/fa";

// This component is here only to to showcase the
// React Context integration. No need to keep this if
// you don't require a Modal in your project.
export default class Modal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
    if (this.props.autoOpen) {
      this.handleModal();
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.open !== this.props.open) {

      if (typeof document !== 'undefined') {
        this.disableScrolling(this.props.open);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = ({ key }) => {
    if (key === 'Escape') {
      this.props.open && this.props.hideModal();
    }
  };

  disableScrolling(open) {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (open) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
      this.setState({ open: false });
    }
  }

  handleModal = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const {
      children,
      button,
      showModal,
      hideModal,
      type,
      arrow,
    } = this.props;

    return (
      <>
        {button ? (
          <Button
            type="button"
            onClick={() => {
              this.handleModal();
              showModal();
            }}
            className={(type === 'contactUs' ? 'contactUs' : type === 'criteria' ? 'criteria' : '')}
          >
            <div className={'divModal' + (type === 'contactUsHeader' ? ' contact-svg-header' : '')}>{button} {type === 'learnMore' ? <FaExternalLinkAlt />
               : ''}</div>
            {arrow ? <IoIosArrowDown /> : null}
          </Button>
        ) : (
          undefined
        )}

        <ConditionsModalDialog
          className={type}
          isOpen={this.state.open && this.props.open}
        >
          <Close
            onClick={() => {
              this.handleModal();
              hideModal();
            }}
          >
            <VisuallyHidden>Close</VisuallyHidden>
          </Close>
          {children}
        </ConditionsModalDialog>
      </>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  button: PropTypes.string,
  type: PropTypes.string,
  autoOpen: PropTypes.bool,
  arrow: PropTypes.bool,
};
