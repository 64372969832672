import React from 'react';
import {Container} from './nav.css';
import PropTypes from 'prop-types';
import { Row, Col, NavLink } from 'reactstrap';

class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);


  }

  showModal() {
    this.setState({
      modalOpen: true,
    });
  }

  hideModal() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    let { footerItem, selectedLanguage } = this.props;
    this.currentLocation =
      typeof window !== 'undefined' ? window.location.pathname.split('/') : '';

    return (
      <Container>
        <Row>
          <Col md={4} sm={12} className={'nav-groups'}>
            <ul className={'first-section'}>
              {footerItem.map((item, index) => (
                <li key={index} className={index <= 3 ? 'show' : 'hide'}>
                  {index <= 3 ? (
                    <NavLink
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.menuTitle}
                    </NavLink>
                  ) : (
                    <NavLink style={{ display: 'none' }} />
                  )}
                </li>
              ))}
            </ul>
          </Col>
          <Col md={4} sm={12} className={'nav-groups'}>
            <ul className={'second-section'}>
              {footerItem.map((item, index) => (
                <li
                  key={index}
                  className={index <= 6 && index > 3 ? 'show' : 'hide'}
                >
                  {index <= 6 &&
                  index > 3 &&
                  (item.url === 'glossary' || item.url === 'sitemap') ? (
                    <NavLink
                      href={`/${selectedLanguage}/${item.url}`}
                      target="_self"
                      className={
                        this.currentLocation[2] === item.url ? 'active' : ''
                      }
                    >
                      {item.menuTitle}
                    </NavLink>
                  ) : (
                    <NavLink
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.menuTitle}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          </Col>
          <Col md={4} sm={12} className={'nav-groups'}>
            <ul className={'third-section'}>
              {footerItem.map((item, index) => (
                <li key={index} className={index > 6 ? 'show' : 'hide'}>
                  {index > 6 ? (
                    <NavLink
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.menuTitle}
                    </NavLink>
                  ) : (
                    ''
                  )}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Nav;

Nav.propTypes = {
  footerItem: PropTypes.any,
  selectedLanguage: PropTypes.any,
};
