import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Container, NavSection } from './footer.css';
import Nav from './nav/nav';
import { Row, Col, NavLink } from 'reactstrap';
import Img from 'gatsby-image';

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      data,
      selectedLanguage,
      logo,
      title,
      poweredText,
      poweredUrl,
      facebook,
      twitter,
      youtube,
      flickr,
      linkedin,
      instagram,
      upper,
    } = this.props;
    let footerMenu = data.site.siteMetadata.footerMenu;
    const footerItem = data.pageMetadataJson.pages
      .filter(page => {
        return (
          page.pageLang === selectedLanguage && footerMenu.includes(page.url)
        );
      })
      .sort((a, b) => {
        return footerMenu.indexOf(a.url) - footerMenu.indexOf(b.url);
      });
    return (
      <Container className={upper ? 'special' : ''}>
        <Row className={'footer-content'}>
          <Col className={'icon-section'} xl={1} lg={1} md={1} sm={12}>
            <NavLink className={'logo-img'} href={`/${selectedLanguage}/`}>
              <Img fluid={logo.childImageSharp.fluid} alt={title} />
            </NavLink>
          </Col>
          <Col className={'nav-section'} xl={7} lg={7} md={11} sm={12}>
            <NavSection>
              <Nav
                footerItem={footerItem}
                selectedLanguage={selectedLanguage}
              />
            </NavSection>
          </Col>
          <Col xl={4} lg={4} md={12} sm={12} className={'social-section'}>
            <a
              href={facebook}
              target="_blank"
              rel="noopener noreferrer"
              className={'icon facebook'}
            >
              <img src="/icons/Facebook.svg" alt="facebook" />
            </a>
            <a
              href={twitter}
              target="_blank"
              rel="noopener noreferrer"
              className={'icon'}
            >
              <img src="/icons/Twitter-icon.svg" alt="twitter" />
            </a>
            <a
              href={youtube}
              target="_blank"
              rel="noopener noreferrer"
              className={'icon'}
            >
              <img src="/icons/Youtube-icon.svg" alt="youtube" />
            </a>
            <a
              href={flickr}
              target="_blank"
              rel="noopener noreferrer"
              className={'icon'}
            >
              <img src="/icons/Flickr.svg" alt="flickr" />
            </a>
            <a
              href={linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className={'icon'}
            >
              <img src="/icons/Linkedin.svg" alt="LinkedIn" />
            </a>
            <a
              href={instagram}
              target="_blank"
              rel="noopener noreferrer"
              className={'icon instagram'}
            >
              <img src="/icons/Instagram.svg" alt="instagram" />
            </a>
            <div className={'power-section'}>
              <p>{poweredText}</p>
              <a
                href={poweredUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex"
              >
                <img src="/icons/citeline_logo.svg" alt="Citeline Logo" />
              </a>
            </div>
          </Col>
          <Col className={'rights'} xs={12}>
            <p>{`© 2001-${new Date().getFullYear()} GlaxoSmithKline plc. All rights reserved.`}</p>
            <p>{`GlaxoSmithKline plc. Registered in England and Wales No. 3888792.`}</p>
            <p>{`Registered office: 980 Great West Road, Brentford, Middlesex, TW8 9GS, United Kingdom.`}</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

const FooterWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            footerMenu
          }
        }
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
);

export default FooterWithQuery;

Footer.propTypes = {
  data: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  logo: PropTypes.any.isRequired,
  title: PropTypes.string,
  poweredUrl: PropTypes.string.isRequired,
  poweredText: PropTypes.string.isRequired,
  facebook: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  youtube: PropTypes.string.isRequired,
  flickr: PropTypes.string.isRequired,
  linkedin: PropTypes.string.isRequired,
  instagram: PropTypes.string.isRequired,
  upper: PropTypes.bool,
};
