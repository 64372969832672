import styled from 'styled-components';

export const BactTop = styled.div`
@media print {
  display: none;
}
  @media (min-width: 1025px) and (max-width: 1200px) {
    div {
      bottom: 150px !important;
    }
  }
  @media (min-width: 993px) and (max-width: 1024px) {
    div {
      bottom: 50px !important;
    }
    span {
      display: none;
    }
  }
  @media (min-width: 551px) and (max-width: 992px) {
    div {
      bottom: 30px !important;
    }
    span {
      display: none;
    }
  }
  @media (max-width: 550px) {
    div {
      bottom: -20px !important;
    }
    span {
      display: none;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .special {
      margin-bottom: 40px;
    }
  }
`;
