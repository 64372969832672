export const accent = '#f90000';
export const white = '#ffffff';
export const black = '#000000';
export const fontColor = '#544F40';
export const fontColorLight = '#72635D';
export const headerBorder = '#CED4DA';
export const activeColor = '#F36533';
export const hoverColor = '#CF4E20';
export const footerBGGrey = '#F5F5F5';
export const generalBgGrey = '#f9f9f9';
export const iconGrey = '#7F7F7F';
export const buttonBG = '#15717D';
export const yellow = '#fed636';
export const blueHover = '#105962';
export const inputDark = '#212529';
