import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './nav.css';
import { StaticQuery, graphql } from 'gatsby';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { FaHome } from 'react-icons/fa';
import { Location } from '@reach/router';
import Modal from '../modal/modal';
import ContactUsHeader from '../contact-us-header/contact-us-header';
import { trackAnalytics } from '../../helpers/trackAnalytics';

class SiteNav extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.currentLocation = [];
    this.state = {
      isOpen: false,
      modalOpen: false,
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  showModal() {
    const newState = { modalOpen: true };
    if (this.state.isOpen) {
      newState.isOpen = false;
    }
    this.setState(newState);
  }

  hideModal() {
    this.setState({
      modalOpen: false,
    });
  }

  renderSimpleMenuItem(page) {
    let { selectedLanguage } = this.props;
    const onlyActives = this.currentLocation.slice(1, 3);
    const isActive = onlyActives.includes(page.url);
    return (
      <NavItem key={page.url} className={'mainMenu'}>
        {page.url === '' ? (
          <NavLink
            href={`/${selectedLanguage}/${page.url}`}
            className={'home-icon'}
          >
            <FaHome className={isActive ? 'active' : ''} />
          </NavLink>
        ) : page.url.includes('http') ? (
          <NavLink
            href={page.url}
            target="_blank"
            className={
              page.menuTitle === 'Learn About Clinical Studies'
                ? 'menu pages'
                : 'top-menu-links'
            }
          >
            {page.menuTitle}
          </NavLink>
        ) : page.url === 'contactUs' ? (
          <NavLink className={'top-menu-links last'}>
            <Modal
              button={'Contact Us'}
              hideModal={this.hideModal}
              showModal={()=>{this.showModal();              
                trackAnalytics('TrialContactClick', {
                  TrialId: '',
                  PositionInList: -1,
                  TrialTitle: '',
                  TrialStatus: '',
                  LocationPosition: -1,
                  Conditions: '',
                });
              }}
              open={this.state.modalOpen}
              type={'contactUsHeader'}
            >
              <ContactUsHeader hideModal={this.hideModal} />
            </Modal>
          </NavLink>
        ) : (
          <NavLink
            href={`/${selectedLanguage}/${page.url}`}
            className={
              (isActive ? 'active' : '') +
              (page.menuTitle === 'Preclinical Publications'
                ? ' last-item'
                : '')
            }
          >
            {page.menuTitle}
          </NavLink>
        )}
      </NavItem>
    );
  }

  renderItem(page, index, menuItemList) {
    return this.renderSimpleMenuItem(page, index, menuItemList);
  }

  render() {
    return (
      <Container>
        <Location>
          {({ location }) => {
            let { menuItems } = this.props;
            this.currentLocation = location.pathname.split('/');

            return (
              <Navbar expand="lg">
                {menuItems.length > 2 &&
                  (this.state.isOpen === false ? (
                    <NavbarToggler
                      onClick={this.toggle}
                      className={'burger-toggler'}
                    >
                      <div className={'img-holder'}>
                        <img src="/icons/burger.svg" alt="menu" />
                      </div>
                    </NavbarToggler>
                  ) : (
                    <NavbarToggler
                      onClick={this.toggle}
                      className={'burger-toggler'}
                    >
                      <div className={'img-holder'}>
                        <img src="/icons/close.svg" alt="close" />
                      </div>
                    </NavbarToggler>
                  ))}
                <Collapse
                  className={'collapse navbar-collapse'}
                  id="navbarCollapse"
                  isOpen={menuItems.length > 1 ? this.state.isOpen : true}
                  navbar
                >
                  <Nav className="ml-auto" navbar>
                    {menuItems
                      .filter(
                        page =>
                          typeof page.parent === 'undefined' ||
                          page.parent === null
                      )
                      .map(this.renderItem.bind(this))}
                  </Nav>
                </Collapse>
              </Navbar>
            );
          }}
        </Location>
      </Container>
    );
  }
}

const NavWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
            index
          }
        }
      }
    `}
    render={data => <SiteNav data={data} {...props} />}
  />
);

export default NavWithQuery;

SiteNav.propTypes = {
  data: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.any.isRequired,
  menuItems: PropTypes.array.isRequired,
};
