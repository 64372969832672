import styled from 'styled-components';
import {footerBGGrey} from "../../../constants/theme";

export const Container = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${footerBGGrey};
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    flex-direction: column;
    li {
      padding: 5px 0;
      &.hide {
      display: none;
      }
    }
  }
`;
