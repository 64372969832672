import styled from 'styled-components';
import { fontColor } from '../../constants/theme';

export const Container = styled.div`
  
  @media (min-width: 320px) and (max-width: 992px) {
    .home-icon {
      position: relative;
      padding: 20px;
    }
  }
  .navbar {
    padding: 0px 0px;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .home-icon {
      left: 8px;
    }
  }
  //IPAD PRO LANDSCAPE
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .navbar {
      padding: 0px 2px 0px 0px;
    }
    .home-icon {
      left: 0px;
    }
  }
  @media (min-width: 320px) and (max-width: 996px) {
    .nav-item-li {
      padding: 0 0;
    }
    .burger-toggler {
      border: none;
      padding: 22px 40px 22px 0;
      min-height: 70px;
      .img-holder {
        height: 24px;
        align-items: center;
        display: flex;
      }
      &:hover {
        outline: none;
      }
      &:active {
        outline: none;
      }
      &:focus {
        outline: none;
      }
    }
    .navbar-expand-lg {
      justify-content: flex-end;
      margin: 0 -20px;
    }
    .navbar-collapse {
      position: absolute;
      top: 70px;
      align-items: flex-start;
      z-index: -2;
      right: 1000%;
      padding: 15px 28px;
      width: 100%;
      transition: all 0.3s ease;
      display: block;
      background-color: white;
    }
    .navbar-collapse.collapsing {
      height: auto !important;
      margin-left: 150%;
      transition: all 0.7s ease;
      display: block;
    }
    .navbar-collapse.show {
      width: 100%;
      left: 0;
      height: 150vh;
      overflow: auto;
    }
    .navbar-nav {
      .item {
        padding: 24px 0;
        border-bottom: 1px solid ${fontColor};
      }
    }
  }
  //
  @media (min-width: 581px) and (max-width: 992px) and (orientation: landscape) {
    .navbar-collapse.show {
      width: 100%;
      left: 0;
      height: 100vh !important;
      overflow: auto;
      .navbar-nav {
        .item {
          padding: 7px 0;
        }
      }
    }
  }
  //small mobile devices like IphoneSE, samsung S5
  @media (min-width: 320px) and (max-width: 580px) and (orientation: landscape) {
    .navbar-collapse.show {
      .navbar-nav {
        .item {
          padding: 4px 0;
        }
      }
    }
  }
`;
